import { useState, useEffect } from 'react';
import PreferencesData from './PreferencesData';
import DivisionPreferences from './DivisionPreferences';
import JobTypePreferences from './JobTypePreferences';
import { motion } from 'framer-motion';

const Preferences = ({
  jobCountByState,
  jobCountByDivision,
  jobCountByJobType,
}) => {
  const [selectedData, setSelectedData] = useState(jobCountByState);
  useEffect(() => {
    setSelectedData(jobCountByState || []);
  }, [jobCountByState]);

  const handleDataSelection = (data) => {
    setSelectedData(data || []);
  };

  const textVariant = {
    initial: {
      x: -80,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1.5,
        staggerChildren: 0.2,
      },
    },
  };

  return (
    <motion.section className="padding-container">
      <motion.div
        variants={textVariant}
        initial="initial"
        whileInView="animate"
      >
        <motion.h2 className="section_header_text" variants={textVariant}>
          Jobs By Preferences
        </motion.h2>
      </motion.div>
      <div className="flex space-x-4 mb-4">
        <button
          onClick={() => handleDataSelection(jobCountByState)}
          className={`cursor-pointer preferences-topic ${
            selectedData === jobCountByState
              ? 'border border-blue-400 bg-gradient-to-r from-blue-500 to-blue-150 text-white py-3 px-7 2xl:py-6 2xl:px-20 rounded-md'
              : 'border-none py-3 px-7 2xl:py-6 2xl:px-20 rounded-md'
          }`}
        >
          State
        </button>
        <button
          onClick={() => handleDataSelection(jobCountByDivision)}
          className={`cursor-pointer preferences-topic ${
            selectedData === jobCountByDivision
              ? 'border border-blue-400 bg-gradient-to-r from-blue-500 to-blue-150 text-white py-3 px-7 2xl:py-6 2xl:px-20 rounded-md'
              : 'border-none py-3 px-7 2xl:py-6 2xl:px-20 rounded-md'
          }`}
        >
          Division
        </button>
        <button
          onClick={() => handleDataSelection(jobCountByJobType)}
          className={`cursor-pointer preferences-topic ${
            selectedData === jobCountByJobType
              ? 'border border-blue-400 bg-gradient-to-r from-blue-500 to-blue-150 text-white py-2 px-7 2xl:py-6 2xl:px-20 rounded-md'
              : 'border-none py-2 px-7 2xl:py-6 2xl:px-20 rounded-md'
          }`}
        >
          Job Type
        </button>
      </div>
      <div className="h-[240px] xl:h-[300px]">
        {selectedData === jobCountByState && jobCountByState?.length > 0 && (
          <PreferencesData data={selectedData} selectedTopic={selectedData} />
        )}
        {selectedData === jobCountByDivision &&
          jobCountByDivision?.length > 0 && (
            <DivisionPreferences
              data={selectedData}
              selectedTopic={selectedData}
            />
          )}
        {selectedData === jobCountByJobType && (
          <JobTypePreferences
            data={selectedData}
            selectedTopic={selectedData}
          />
        )}
        {selectedData?.length === 0 && (
          <div>No data available for the selected preference.</div>
        )}
      </div>
    </motion.section>
  );
};

export default Preferences;
