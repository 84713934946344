import { useState } from 'react';
import Slider from 'react-slick';
import { slickSettings } from '@/lib/Caresoul/CaresoulMainSettings';
import PermanentJobCard from '@/components/cards/jobCard/PermanentJobCard';
import ShareModal from '@/components/models/ShareModal';
import SubmitFormModal from '@/components/models/SubmitFormModal';
import { motion } from 'framer-motion';

export default function Offer({ data }) {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const [isSubmitFormModalOpen, setIsSubmitFormModalOpen] =
    useState<boolean>(false);
  const [jobUrl, setJobUrl] = useState<string>('');

  const settings = slickSettings({ activeIndex, setActiveIndex });

  const filteredJobData = data?.filter((job) => job.is_permanent === '1');

  const handleShareClick = (url: string) => {
    setJobUrl(url);
    setIsShareModalOpen(true);
  };

  const handleApplyClick = () => {
    setIsSubmitFormModalOpen(true);
  };

  const handleCloseShareModal = () => {
    setIsShareModalOpen(false);
  };

  const handleCloseSubmitFormModal = () => {
    setIsSubmitFormModalOpen(false);
  };

  const Variant = {
    initial: {
      scale: 0.8,
      opacity: 0.5,
    },
    animate: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.2,
      },
    },
  };

  const textVariant = {
    initial: {
      x: -80,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1.5,
        staggerChildren: 0.2,
      },
    },
  };

  return (
    <motion.section className="padding-container">
      <motion.div
        className="flex items-baseline justify-between"
        variants={textVariant}
        initial="initial"
        whileInView="animate"
      >
        <motion.h2 className="section_header_text" variants={textVariant}>
          Jobs With Exceptional Offer
        </motion.h2>
      </motion.div>
      <motion.div
        className="my-5"
        variants={Variant}
        initial="initial"
        whileInView="animate"
      >
        <Slider {...settings}>
          {filteredJobData?.slice(0, 12)?.map((offer) => (
            <div key={offer.jobdetails_id} className="my-1">
              <PermanentJobCard
                offer={offer}
                onShareClick={handleShareClick}
                onApplyClick={handleApplyClick}
              />
            </div>
          ))}
        </Slider>
      </motion.div>
      <div className={`modal ${isShareModalOpen ? 'modal-open' : ''}`}>
        <ShareModal
          isOpen={isShareModalOpen}
          onClose={handleCloseShareModal}
          jobUrl={jobUrl}
        />
      </div>
      <div className={`modal ${isSubmitFormModalOpen ? 'modal-open' : ''}`}>
        <SubmitFormModal
          isOpen={isSubmitFormModalOpen}
          onClose={handleCloseSubmitFormModal}
        />
      </div>
    </motion.section>
  );
}
