import Button from '@/components/shared/button/Button';
import { formatJobNameForURL } from '@/utils/formatJobNameforURL';
import calculateDaysDifference from '@/utils/getNoOfDatesUtil';
import { useNavigate } from 'react-router-dom';

const MobileJobCard = ({ offer }) => {
  const navigate = useNavigate();
  const handleNavigateAdv = (event: React.MouseEvent) => {
    localStorage.setItem('jobData', JSON.stringify(offer));
    event.stopPropagation();
    const jobUrl = formatJobNameForURL(offer.job_title);
    if (offer.is_permanent === '1') {
      navigate(`/apply-now/permanent/${jobUrl}/${offer.jobdetails_id}`, {
        state: { jobData: offer },
      });
    } else {
      navigate(`/apply-now/locum/${jobUrl}/${offer.jobdetails_id}`, {
        state: { jobData: offer },
      });
    }
    window.scrollTo(0, 0);
  };

  return (
    <div
      key={offer.jobdetails_id}
      className="max-w-[20rem] px-1 md:mb-[6px]"
      onClick={handleNavigateAdv}
    >
      <div className="drop-shadow-xl rounded-md flex flex-col items-stretch justify-between p-3 bg-white cursor-pointer">
        <h1 className="card-text-header">{offer.job_title}</h1>
        <div className="flex items-center justify-between font-sans font-normal text-gray-400 my-2">
          <h2 className="text-blue-400 font-semibold text-xs">
            {offer.profession?.name}
          </h2>
          <h2 className="text-sm text-gray-480">
            {calculateDaysDifference(offer.commencement_date)} days
          </h2>
        </div>
        <div className="flex items-center justify-between">
          <h3 className="card-text-items w-1/2 h-[56px]">
            {offer.billing_share && offer.hourly_fee ? (
              <p className="line-clamp-1">{`${offer.hourly_fee} or ${offer.billing_share}`}</p>
            ) : (
              <p className="line-clamp-1">
                {offer.hourly_fee || offer.billing_share}
              </p>
            )}
          </h3>
          <Button
            type="button"
            title="View More"
            variant="mobile-apply-now"
            handleClick={handleNavigateAdv}
          />
        </div>
      </div>
    </div>
  );
};

export default MobileJobCard;
