import { AWS_MOBILE_CITY_URL } from '@/config/seviceApiConfig';
import { formatNameForURL } from '@/utils/formatNameForURL';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';

interface Job {
  id: number;
  name: string;
  jobs: string;
  url: string;
}

interface City {
  id: number;
  title: string;
  data: Job[];
}

interface JobsByMajorCitiesDataProps {
  city: City;
}

const JobsByMajorCitiesData: React.FC<JobsByMajorCitiesDataProps> = ({
  suburbData,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const navigate = useNavigate();

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
    touchMove: true,
    appendDots: (dots: JSX.Element[]) => (
      <ul className="slick-dots">
        {dots.map((dot, index) => (
          <li key={index} className="inline-block m-2 cursor-pointer">
            {dot}
          </li>
        ))}
      </ul>
    ),
    customPaging: (i: number) => (
      <div
        className={`w-[0.688rem] h-[0.688rem] lg:w-[0.813rem] lg:h-[0.813rem] rounded-full mt-6 ${i === activeIndex ? 'hover:bg-gradient-to-r from-blue-500 transition duration-300 text-center border border-blue-400 to-blue-150 text-blue-700 hover:text-white py-4 px-3 rounded-md' : 'border-none py-4 px-3  rounded-md text-center text-blue-700 hover:text-gray-120'}`}
      />
    ),
    beforeChange: () => {
      const maxIndex = 2;
      // const maxIndex = Math.ceil(data.length / settings.slidesToShow) - 1;
      setActiveIndex((oldIndex) => {
        const newIndex = oldIndex + 1;
        return newIndex > maxIndex ? 0 : newIndex;
      });
    },
  };

  const handleNavigateToFilter = (cityName: string, suburbID: number) => {
    navigate(`/city/all-jobs/${cityName}`, { state: { suburbID } });
  };

  return (
    <div className="city-jobs-slider">
      <Slider {...settings}>
        {suburbData?.map((city) => (
          <div
            key={city.suberbs_id}
            className="mr-2 relative"
            onClick={() =>
              handleNavigateToFilter(
                formatNameForURL(city.name),
                city.suberbs_id
              )
            }
          >
            <img
              src={`${AWS_MOBILE_CITY_URL}${city.image}`}
              alt={city.image_alt}
              className="object-cover object-center"
            />
            <div className="absolute top-1/2 left-[10%]">
              <h4 className="city-card-main">{city.name}</h4>
              <h6 className="city-card-sub">{city.jobdetails_count} Jobs</h6>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default JobsByMajorCitiesData;
