import { motion, HTMLMotionProps } from 'framer-motion';
import { ButtonHTMLAttributes } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  type: 'button' | 'submit' | 'reset';
  title: string;
  variant: string;
  disableVariant: string;
  iconSrc?: string;
  isSubmitting?: boolean;
  submitTitle?: string;
}

const SubmitButton = ({
  type,
  title,
  variant,
  disableVariant,
  submitTitle,
  iconSrc,
  isSubmitting = false,
  ...props
}: ButtonProps & HTMLMotionProps<'button'>) => {
  const iconHoverVariant = {
    hover: {
      rotate: 360,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <motion.button
      className={`relative border-none rounded-md lg:rounded-[3px] cursor-pointer flex items-center justify-center ${isSubmitting ? disableVariant : variant}`}
      type={type}
      disabled={isSubmitting}
      {...props}
      whileHover="hover"
    >
      <span className="text-nowrap text-sm lg:text-base">
        {isSubmitting ? submitTitle : title}
      </span>
      {iconSrc && (
        <motion.img
          src={iconSrc}
          alt="Icon"
          className="ml-2 w-4 h-4"
          variants={iconHoverVariant}
          whileHover="hover"
        />
      )}
    </motion.button>
  );
};

export default SubmitButton;
