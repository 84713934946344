import { Link, useNavigate } from 'react-router-dom';
import { HeroMegaMenu, Logo } from '@/components';
import { useState } from 'react';
import { TOP_NAV_LINKS } from '@/constants/NavbarConstants';
import useFetchStatesWithRegions from '@/hooks/useFetchStatewithRegions';
import useTopMegaMenu from '@/hooks/useScollTopNavMenu';
import TopMenuEmployers from '@/components/mainMenu/TopMenu';

function TopNavbar({ stateWithRegions, fetchStatesWithRegions }) {
  const [menuData, setMenuData] = useState([]);
  // const { stateWithRegions, fetchStatesWithRegions } =
  //   useFetchStatesWithRegions();
  const navigate = useNavigate();

  const {
    selectedLink,
    isMegaMenuOpen,
    isPolygonVisible,
    isEmployerMenuOpen,
    handleLinkClick,
    handleMouseEnter,
    handleMouseLeave,
    handleMenuMouseEnter,
    handleMenuMouseLeave,
    handlePolygonMouseEnter,
    handlePolygonMouseLeave,
    menuRef,
    megaMenuRef,
    polygonRef,
    handleNavigation,
    employerMenuRef,
  } = useTopMegaMenu();

  const handleClick = () => {
    handleNavigation();
    navigate('/');
    window.scrollTo(0, 0);
  };

  const handleBrowseJobsClick = async (event) => {
    event.preventDefault();
    try {
      await fetchStatesWithRegions();
      if (stateWithRegions) {
        setMenuData(stateWithRegions);
      }
      handleLinkClick('browserJobs', navigate);
    } catch (error) {
      console.error('Error fetching jobs data:', error);
    }
  };

  const handleEmployerMouseEnter = (event) => {
    event.preventDefault();
    handleMouseEnter('employers');
  };

  return (
    <section className="sticky top-0 z-10">
      <nav className="nav-container relative" ref={menuRef}>
        <div className="flex items-center">
          <Logo height={57} width={207} handleClick={handleClick} />
        </div>
        <div className="flex items-center pr-8">
          <ul className="flex items-center cursor-pointer lg:gap-[30px] xl:gap-[50px] 2xl:gap-[80px]">
            {TOP_NAV_LINKS?.map((link) => (
              <div key={link.key} className="relative cursor-pointer">
                <Link
                  to={link.href}
                  className={`top-nav-link ${selectedLink === link.key ? 'active' : ''}`}
                  onMouseEnter={(event) => {
                    if (link.key === 'browserJobs') {
                      handleBrowseJobsClick(event);
                    } else if (link.key === 'employers') {
                      handleEmployerMouseEnter(event);
                    }
                  }}
                  onMouseLeave={() => {
                    handleMouseLeave();
                    handleMenuMouseLeave();
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    handleLinkClick(link.href, navigate);
                  }}
                >
                  {link.label === 'Browse Jobs' ? (
                    <span className="flex items-center">
                      <img
                        src={link.icon}
                        alt="Browse Jobs"
                        className="w-4 h-4 mr-2"
                      />
                      {link.label}
                    </span>
                  ) : (
                    link.label
                  )}
                </Link>
                {link.key === 'browserJobs' &&
                  selectedLink &&
                  isMegaMenuOpen && (
                    <div
                      onMouseEnter={handleMenuMouseEnter}
                      onMouseLeave={handleMenuMouseLeave}
                    >
                      <HeroMegaMenu ref={megaMenuRef} data={menuData} />
                    </div>
                  )}
                {link.key === 'employers' &&
                  selectedLink &&
                  isEmployerMenuOpen && (
                    <div
                      onMouseEnter={handleMenuMouseEnter}
                      onMouseLeave={handleMenuMouseLeave}
                    >
                      <TopMenuEmployers
                        ref={employerMenuRef}
                        data={link.subLinks}
                      />
                    </div>
                  )}
                {selectedLink === link.key && isPolygonVisible && (
                  <div
                    className="polygon-shape-top"
                    onMouseEnter={handlePolygonMouseEnter}
                    onMouseLeave={handlePolygonMouseLeave}
                    ref={polygonRef}
                  ></div>
                )}
              </div>
            ))}
          </ul>
        </div>
      </nav>
    </section>
  );
}

export default TopNavbar;
