import GetAllJobCountByApi from '@/api/GetAllJobCountForHomePageApi';
import { useEffect, useState } from 'react';

interface AllJobData {}

interface FetchAllJobData {
  allJobCount: AllJobData[] | null;
  loading: boolean;
  error: Error | null;
}

export default function useFetchAllJobCount(): FetchAllJobData {
  const [allJobCount, setAllJobCount] = useState<AllJobData[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await GetAllJobCountByApi();
      setAllJobCount(response);
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { allJobCount, loading, error };
}
