import { Link } from 'react-router-dom';

export default function PreferencesData({ data, selectedTopic }) {
  return (
    <div className="my-5">
      {selectedTopic ? (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 2xl:gap-4 mt-10">
          {data?.map((entry, index) => (
            <Link
              key={index}
              className="p-2 2xl:p-4 rounded-lg"
              // to={`/state/${entry.url}`}
            >
              <p className="preferences-text-main">{entry?.name}</p>
              <p className="preferences-text-sub">{entry?.job_count}</p>
            </Link>
          ))}
        </div>
      ) : (
        <div>No entries available for the selected topic.</div>
      )}
    </div>
  );
}
