import { useEffect, useState } from 'react';
import Button from '../../shared/button/Button';
import { HeroStatus } from '@/components';
import useFetchAllProfessions from '@/components/forms/selectors/hook/useFetchAllProfessions';
import useFetchAllState from '@/components/forms/selectors/hook/useFetchAllStates';
import { useNavigate } from 'react-router-dom';
import { formatNameForURL } from '@/utils/formatNameForURL';
import { ProfessionSelector } from '@/components/forms/selectors/ProfessionSelector';
import { StateSelector } from '@/components/forms/selectors/StateSelector';

const JobFinder = ({ totalCount }) => {
  const navigate = useNavigate();
  const [selectedProfession, setSelectedProfession] = useState(null);
  const [selectedState, setSelectedLocation] = useState(null);
  const { allProfessionsData } = useFetchAllProfessions();
  const { allStates } = useFetchAllState();
  const [stateName, setSateName] = useState('');
  const [professionName, setProfessionName] = useState('');
  const [url, setUrl] = useState('');
  const [stateUrl, setStateURl] = useState('');

  useEffect(() => {
    if (selectedState) {
      const selectedStateData = allStates?.find(
        (st) => st.state_id === parseInt(selectedState)
      );
      if (selectedStateData) {
        setSateName(selectedStateData.name);
        setStateURl(selectedStateData.url);
      } else {
        setSateName('');
        setStateURl('');
      }
    } else {
      setSateName('');
      setStateURl('');
    }
  }, [selectedState, allStates]);

  useEffect(() => {
    if (selectedProfession) {
      const selectedProfessionData = allProfessionsData?.find(
        (prof) => prof.profession_id === parseInt(selectedProfession)
      );
      if (selectedProfessionData) {
        setProfessionName(selectedProfessionData.name);
        setUrl(selectedProfessionData.url);
      } else {
        setProfessionName('');
        setUrl('');
      }
    } else {
      setProfessionName('');
      setUrl('');
    }
  }, [selectedProfession, allProfessionsData]);

  const handleFindJobs = () => {
    if (selectedProfession && selectedState) {
      navigate(
        `/permanent-jobs/${formatNameForURL(stateName)}/${formatNameForURL(professionName)}-jobs`,
        { state: { selectedState, selectedProfession } }
      );
      window.scrollTo(0, 0);
    } else if (selectedProfession) {
      navigate(`profession/${url}`);
      window.scrollTo(0, 0);
    } else if (selectedState) {
      navigate(`state/${stateUrl}`);
      window.scrollTo(0, 0);
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="lg:bg-white lg:flex items-center justify-between lg:p-[6px] 2xl:p-[10px] rounded-sm w-full border-none lg:border-2 border-blue-400">
        <div className="bg-white w-full rounded-sm border-2 border-blue-400 lg:border-none lg:bg-transparent lg:w-[280px] lg:rounded-none select-wrapper">
          <div className="relative min-w-[180px] xl:min-w-[280px]">
            <ProfessionSelector
              onChange={(value) => setSelectedProfession(value)}
            />
          </div>
        </div>
        <div className="hidden lg:flex border-r border border-blue-400 h-10 mx-4"></div>
        <div className="bg-white w-full rounded-sm border-2 border-blue-400 mt-4 lg:mt-0 lg:border-none lg:bg-transparent lg:w-[280px] lg:rounded-none select-wrapper">
          <div className="relative min-w-[180px] xl:min-w-[280px]">
            <StateSelector onChange={(value) => setSelectedLocation(value)} />
          </div>
        </div>
        <div className="mt-4 lg:mt-0 lg:w-[150px] xl:w-[172px]  lg:ml-4 ">
          <Button
            type="button"
            title="Find Jobs"
            variant="find-jobs-btn"
            handleClick={handleFindJobs}
          />
        </div>
      </div>
      <div className="hidden lg:flex items-center justify-between mt-5 w-[600px] h-[87px]">
        <HeroStatus totalCount={totalCount} />
      </div>
    </>
  );
};

export default JobFinder;
