import SearchIcon from '@/assets/icons/Search.png';
import SearchIconBlack from '@/assets/icons/SearchIconBlack.png';
import HomeIcon from '/icons/mobileNavbar/HomeIcon.png';
import ProfileIcon from '/icons/mobileNavbar/ProfileIcon.png';
import UsersIcon from '/icons/mobileNavbar/UsersIcon.png';
import ExploreIcon from '/icons/mobileNavbar/ExploreIcon.png';
import DoctorIcon from '/icons/mobileNavbar/DoctorIcon.png';
import AHPIcon from '/icons/mobileNavbar/AHPIcon.png';
import OralHealthIcon from '/icons/mobileNavbar/OralHealthIcon.png';
import NursingIcon from '/icons/mobileNavbar/NursingIcon.png';
import HealthCareIcon from '/icons/mobileNavbar/HealthCareIcon.png';
import ContactIcon from '/icons/mobileNavbar/ContactIcon.png';

export const MOBILE_MENU_LINKS = [
  {
    href: '/',
    key: 'home',
    label: 'Home',
    icon: HomeIcon,
  },
  {
    href: '/',
    key: 'employers',
    label: 'Employers',
    icon: UsersIcon,
    subLinks: [
      {
        id: 1,
        href: '/employer-service',
        label: 'Empowering healthcare employers',
        icon: 'empowering_healthcare_employers.png',
      },
      {
        id: 2,
        href: '/permanent-recruitment',
        label: 'Permanent recruitment services',
        icon: 'permanent_recruitment_services.png',
      },
      {
        id: 3,
        href: '/temporary-recruitment',
        label: 'Temporary recruitment services',
        icon: 'temporary_recruitment_services.png',
      },
      {
        id: 4,
        href: '/international-recruitment',
        label: 'International recruitment services',
        icon: 'international_recruitment_services.png',
      },
    ],
  },
  // {
  //   href: '/contact-us',
  //   key: 'contact',
  //   label: 'Contact',
  //   icon: ContactIcon,
  // },
];

export const ICON_FOR_LINKS = [
  { id: 1, icon: DoctorIcon },
  { id: 2, icon: AHPIcon },
  { id: 3, icon: OralHealthIcon },
  { id: 4, icon: NursingIcon },
  { id: 5, icon: HealthCareIcon },
  { id: 6, icon: ContactIcon },
];

export const TOP_NAV_LINKS = [
  {
    href: '/all-jobs-in-australia',
    key: 'browserJobs',
    label: 'Browse Jobs',
    icon: SearchIcon,
  },
  {
    href: '',
    key: 'employers',
    label: 'Employers',
    subLinks: [
      {
        id: 1,
        href: '/employer-service',
        label: 'Empowering healthcare employers',
        icon: 'empowering_healthcare_employers.png',
      },
      {
        id: 2,
        href: '/permanent-recruitment',
        label: 'Permanent recruitment services',
        icon: 'permanent_recruitment_services.png',
      },
      {
        id: 3,
        href: '/temporary-recruitment',
        label: 'Temporary recruitment services',
        icon: 'temporary_recruitment_services.png',
      },
      {
        id: 4,
        href: '/international-recruitment',
        label: 'International recruitment services',
        icon: 'international_recruitment_services.png',
      },
    ],
  },
  {
    href: '/contact-us',
    key: 'contact',
    label: 'Contact',
  },
  {
    href: '/sign-in',
    key: 'signIn',
    label: 'Sign In',
  },
];

export const TOP_NAV_LINKS_COMMON = [
  {
    href: '/all-jobs-in-australia',
    key: 'browserJobs',
    label: 'Browse Jobs',
    icon: SearchIconBlack,
  },
  {
    href: '',
    key: 'employers',
    label: 'Employers',
    subLinks: [
      {
        id: 1,
        href: '/employer-service',
        label: 'Empowering healthcare employers',
        icon: 'empowering_healthcare_employers.png',
      },
      {
        id: 2,
        href: '/permanent-recruitment',
        label: 'Permanent recruitment services',
        icon: 'permanent_recruitment_services.png',
      },
      {
        id: 3,
        href: '/temporary-recruitment',
        label: 'Temporary recruitment services',
        icon: 'temporary_recruitment_services.png',
      },
      {
        id: 4,
        href: '/international-recruitment',
        label: 'International recruitment services',
        icon: 'international_recruitment_services.png',
      },
    ],
  },
  {
    href: '/contact-us',
    key: 'contact',
    label: 'Contact',
  },
  {
    href: '/sign-in',
    key: 'signIn',
    label: 'Sign In',
  },
];

export const BOTTOM_NAV_LINKS = [
  {
    title: 'Doctor',
    subtopics: [
      {
        name: 'GP Tele Health jobs',
        subheadings: [
          'RMO Jobs',
          'Registrar Jobs',
          'Specialist Jobs',
          'Doctor - Permanent Jobs',
          'Doctor - Locum Jobs',
        ],
      },
      {
        name: 'GP Jobs',
        subheadings: [
          'General Dentistry',
          'Orthodontics',
          'Oral Surgery',
          'Pediatric Dentistry',
        ],
      },
      {
        name: 'Emergency Medicine Jobs',
        subheadings: [
          'Community Pharmacy',
          'Hospital Pharmacy',
          'Clinical Pharmacy',
          'Industrial Pharmacy',
        ],
      },
      {
        name: 'Anesthetics Jobs',
        subheadings: [
          'Physical Therapist',
          'Occupational Therapist',
          'Speech Therapist',
          'Behavioral Therapist',
        ],
      },
      {
        name: 'O&G Jobs',
        subheadings: [
          'General Practitioner',
          'Specialist',
          'Surgeon',
          'Physician',
        ],
      },
      {
        name: 'Surgery Jobs',
        subheadings: [
          'Registered Nurse',
          'Licensed Practical Nurse',
          'Nurse Practitioner',
          'Nurse Educator',
        ],
      },
      {
        name: 'Gastroenterology Jobs',
        subheadings: [
          'Registered Nurse',
          'Licensed Practical Nurse',
          'Nurse Practitioner',
          'Nurse Educator',
        ],
      },
      {
        name: 'General Medicine Jobs',
        subheadings: [
          'Registered Nurse',
          'Licensed Practical Nurse',
          'Nurse Practitioner',
          'Nurse Educator',
        ],
      },
      {
        name: 'Psychiatry Jobs',
        subheadings: [
          'Registered Nurse',
          'Licensed Practical Nurse',
          'Nurse Practitioner',
          'Nurse Educator',
        ],
      },
      {
        name: 'Rural Generalist',
        subheadings: [
          'Registered Nurse',
          'Licensed Practical Nurse',
          'Nurse Practitioner',
          'Nurse Educator',
        ],
      },
    ],
  },
  {
    title: 'AHP',
    subtopics: [
      'Cardiology',
      'Radiology',
      'Oncology',
      'Pediatrics',
      'Psychiatry',
      'General Practice',
      'Emergency Medicine',
    ],
  },
  {
    title: 'Oral Health',
    subtopics: [
      {
        name: 'GP Tele Health jobs',
        subheadings: [
          'RMO Jobs',
          'Registrar Jobs',
          'Specialist Jobs',
          'Doctor - Permanent Jobs',
          'Doctor - Locum Jobs',
        ],
      },
      {
        name: 'GP Jobs',
        subheadings: [
          'General Dentistry',
          'Orthodontics',
          'Oral Surgery',
          'Pediatric Dentistry',
        ],
      },
      {
        name: 'Emergency Medicine Jobs',
        subheadings: [
          'Community Pharmacy',
          'Hospital Pharmacy',
          'Clinical Pharmacy',
          'Industrial Pharmacy',
        ],
      },
      {
        name: 'Anesthetics Jobs',
        subheadings: [
          'Physical Therapist',
          'Occupational Therapist',
          'Speech Therapist',
          'Behavioral Therapist',
        ],
      },
      {
        name: 'O&G Jobs',
        subheadings: [
          'General Practitioner',
          'Specialist',
          'Surgeon',
          'Physician',
        ],
      },
      {
        name: 'Surgery Jobs',
        subheadings: [
          'Registered Nurse',
          'Licensed Practical Nurse',
          'Nurse Practitioner',
          'Nurse Educator',
        ],
      },
      {
        name: 'Gastroenterology Jobs',
        subheadings: [
          'Registered Nurse',
          'Licensed Practical Nurse',
          'Nurse Practitioner',
          'Nurse Educator',
        ],
      },
      {
        name: 'General Medicine Jobs',
        subheadings: [
          'Registered Nurse',
          'Licensed Practical Nurse',
          'Nurse Practitioner',
          'Nurse Educator',
        ],
      },
      {
        name: 'Psychiatry Jobs',
        subheadings: [
          'Registered Nurse',
          'Licensed Practical Nurse',
          'Nurse Practitioner',
          'Nurse Educator',
        ],
      },
      {
        name: 'Rural Generalist',
        subheadings: [
          'Registered Nurse',
          'Licensed Practical Nurse',
          'Nurse Practitioner',
          'Nurse Educator',
        ],
      },
    ],
  },
  {
    title: 'Mental Health',
    subtopics: [
      {
        name: 'Western Australia',
        subheadings: ['Country WA', 'South Perth', 'North Perth'],
      },
      {
        name: 'Victoria',
        subheadings: ['Melbourne', 'Geelong', 'Ballarat'],
      },
      {
        name: 'Tasmania',
        subheadings: ['Hobart', 'Launceston', 'Devonport'],
      },
      {
        name: 'South Australia',
        subheadings: ['Adelaide', 'Mount Gambier', 'Whyalla'],
      },
      {
        name: 'Queensland',
        subheadings: ['Brisbane', 'Gold Coast', 'Cairns'],
      },
      {
        name: 'Northern Territory',
        subheadings: ['Darwin', 'Alice Springs', 'Katherine'],
      },
      {
        name: 'New South Wales',
        subheadings: ['Sydney', 'Newcastle', 'Wollongong'],
      },
      {
        name: 'Australian Capital Territory',
        subheadings: ['Canberra'],
      },
    ],
  },
  {
    title: 'Nursing',
    subtopics: [
      {
        name: 'GP Tele Health jobs',
        subheadings: [
          'RMO Jobs',
          'Registrar Jobs',
          'Specialist Jobs',
          'Doctor - Permanent Jobs',
          'Doctor - Locum Jobs',
        ],
      },
      {
        name: 'GP Jobs',
        subheadings: [
          'General Dentistry',
          'Orthodontics',
          'Oral Surgery',
          'Pediatric Dentistry',
        ],
      },
      {
        name: 'Emergency Medicine Jobs',
        subheadings: [
          'Community Pharmacy',
          'Hospital Pharmacy',
          'Clinical Pharmacy',
          'Industrial Pharmacy',
        ],
      },
      {
        name: 'Anesthetics Jobs',
        subheadings: [
          'Physical Therapist',
          'Occupational Therapist',
          'Speech Therapist',
          'Behavioral Therapist',
        ],
      },
      {
        name: 'O&G Jobs',
        subheadings: [
          'General Practitioner',
          'Specialist',
          'Surgeon',
          'Physician',
        ],
      },
      {
        name: 'Surgery Jobs',
        subheadings: [
          'Registered Nurse',
          'Licensed Practical Nurse',
          'Nurse Practitioner',
          'Nurse Educator',
        ],
      },
      {
        name: 'Gastroenterology Jobs',
        subheadings: [
          'Registered Nurse',
          'Licensed Practical Nurse',
          'Nurse Practitioner',
          'Nurse Educator',
        ],
      },
      {
        name: 'General Medicine Jobs',
        subheadings: [
          'Registered Nurse',
          'Licensed Practical Nurse',
          'Nurse Practitioner',
          'Nurse Educator',
        ],
      },
      {
        name: 'Psychiatry Jobs',
        subheadings: [
          'Registered Nurse',
          'Licensed Practical Nurse',
          'Nurse Practitioner',
          'Nurse Educator',
        ],
      },
      {
        name: 'Rural Generalist',
        subheadings: [
          'Registered Nurse',
          'Licensed Practical Nurse',
          'Nurse Practitioner',
          'Nurse Educator',
        ],
      },
    ],
  },
  {
    title: 'Healthcare Executive',
    subtopics: [
      {
        name: 'GP Tele Health jobs',
        subheadings: [
          'RMO Jobs',
          'Registrar Jobs',
          'Specialist Jobs',
          'Doctor - Permanent Jobs',
          'Doctor - Locum Jobs',
        ],
      },
      {
        name: 'GP Jobs',
        subheadings: [
          'General Dentistry',
          'Orthodontics',
          'Oral Surgery',
          'Pediatric Dentistry',
        ],
      },
      {
        name: 'Emergency Medicine Jobs',
        subheadings: [
          'Community Pharmacy',
          'Hospital Pharmacy',
          'Clinical Pharmacy',
          'Industrial Pharmacy',
        ],
      },
      {
        name: 'Anesthetics Jobs',
        subheadings: [
          'Physical Therapist',
          'Occupational Therapist',
          'Speech Therapist',
          'Behavioral Therapist',
        ],
      },
      {
        name: 'O&G Jobs',
        subheadings: [
          'General Practitioner',
          'Specialist',
          'Surgeon',
          'Physician',
        ],
      },
      {
        name: 'Surgery Jobs',
        subheadings: [
          'Registered Nurse',
          'Licensed Practical Nurse',
          'Nurse Practitioner',
          'Nurse Educator',
        ],
      },
      {
        name: 'Gastroenterology Jobs',
        subheadings: [
          'Registered Nurse',
          'Licensed Practical Nurse',
          'Nurse Practitioner',
          'Nurse Educator',
        ],
      },
      {
        name: 'General Medicine Jobs',
        subheadings: [
          'Registered Nurse',
          'Licensed Practical Nurse',
          'Nurse Practitioner',
          'Nurse Educator',
        ],
      },
      {
        name: 'Psychiatry Jobs',
        subheadings: [
          'Registered Nurse',
          'Licensed Practical Nurse',
          'Nurse Practitioner',
          'Nurse Educator',
        ],
      },
      {
        name: 'Rural Generalist',
        subheadings: [
          'Registered Nurse',
          'Licensed Practical Nurse',
          'Nurse Practitioner',
          'Nurse Educator',
        ],
      },
    ],
  },
];
