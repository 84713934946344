import { motion } from 'framer-motion';
import { HeroCommonProps } from '../../types/types';

const textVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
};

const iconVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: { opacity: 1, x: 0 },
};

export default function HeroBackground({
  title,
  description,
  icon,
}: HeroCommonProps) {
  return (
    <section
      id="hero"
      className="max-width-container relative cater3-movingBG bg-cover bg-center xl:h-[580px] md:h-[500px] 4xl:h-[635px] w-screen text-center flex items-center justify-center overflow-hidden"
    >
      {/* <div className="stars">
        {[...Array(100)].map((_, index) => (
          <div key={index} className={`star star-${index % 3}`}></div>
        ))}
      </div> */}
      <div className="text-white relative">
        <motion.h1
          className="common-hero-main w-[1082px] lg:w-[1100px] h-[76px]"
          initial="hidden"
          animate="visible"
          variants={textVariants}
          transition={{ duration: 2 }}
        >
          {title}
        </motion.h1>
        <motion.div
          className="flex items-center justify-center mt-32"
          initial="hidden"
          animate="visible"
          variants={iconVariants}
          transition={{ duration: 2 }}
        >
          {icon && <img className="mr-2" src={icon} alt="location" />}
          <p className="commonHero-location-text">{description}</p>
        </motion.div>
      </div>
    </section>
  );
}
