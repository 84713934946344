import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import useFetchAllProfessions from './hook/useFetchAllProfessions';
import useResponsive from '@/hooks/useResponsive';
import { useLocation } from 'react-router-dom';

interface SelectorProps {
  onChange?: (value: string) => void;
  error?: string;
  title?: string;
  resetSelector?: boolean;
  professionId?: string;
}

const ProfessionSelector: React.FC<SelectorProps> = ({
  onChange,
  error,
  title = 'Profession',
  resetSelector = false,
  professionId,
}) => {
  const [selectedProfession, setSelectedProfession] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const { allProfessionsData } = useFetchAllProfessions();
  const { isMobile } = useResponsive();
  const location = useLocation();
  const [defaultOptions, setDefaultOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const mapResponseToValuesAndLabels = (data: any) => ({
    value: data.profession_id.toString(),
    label: data.name,
  });

  useEffect(() => {
    if (allProfessionsData) {
      const mappedOptions = allProfessionsData.map(
        mapResponseToValuesAndLabels
      );
      setDefaultOptions(mappedOptions);
    }
  }, [allProfessionsData]);

  useEffect(() => {
    if (resetSelector) {
      handleReset();
    }
  }, [resetSelector]);

  useEffect(() => {
    if (professionId && allProfessionsData) {
      const profession = allProfessionsData.find(
        (p) => p.profession_id.toString() === professionId.toString()
      );
      if (profession) {
        const mappedProfession = mapResponseToValuesAndLabels(profession);
        setSelectedProfession(mappedProfession);
      }
    }
  }, [professionId, allProfessionsData]);

  const handleReset = () => {
    setSelectedProfession(null);
    onChange && onChange('');
  };

  const loadProfessions = (
    inputValue: string,
    callback: (options: { value: string; label: string }[]) => void
  ) => {
    if (!allProfessionsData) {
      callback([]);
      return;
    }

    const filteredOptions = allProfessionsData
      .map(mapResponseToValuesAndLabels)
      .filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));

    callback(filteredOptions);
  };

  const handleChange = (selectedOption: any) => {
    setSelectedProfession(selectedOption);
    onChange && onChange(selectedOption ? selectedOption.value : '');
  };

  const renderFormStyle = (hasError: boolean) => {
    const path = location.pathname;
    let baseClass = isMobile ? 'mobile-form-container' : 'form-container';

    if (path === '/') {
      return `${baseClass} outline-none`;
    } else if (path === '/premier-locum-general-practitioner') {
      baseClass = `locum-form-container ${hasError ? 'outline-red-300' : 'outline-blue-560'}`;
    } else if (
      path === '/premier-locum-doctor' ||
      path === '/spot-workforce' ||
      path === '/nursing'
    ) {
      baseClass = `locum-form-container ${hasError ? 'outline-red-300' : 'outline-white'}`;
    } else {
      baseClass = `${baseClass} ${hasError ? 'outline-red-300' : 'outline-blue-250'}`;
    }

    return baseClass;
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      boxShadow: 'none',
      border: 'none',
      outline: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? 'rgba(28, 155, 221, 1)'
        : state.isFocused
          ? '#ecf0f1'
          : null,
      color: state.isSelected || state.isFocused ? '#515869' : '#515869',
      animation: 'fadeInPositive 0.5s ease-in-out',
    }),
    menu: (provided) => ({
      ...provided,
      backdropFilter: 'blur(10px)',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: () => ({
      display: 'none',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      marginRight: '8px',
      marginBottom: '0px',
    }),
    menuList: (provided) => ({
      ...provided,
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 5px grey',
        borderRadius: '0px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#A8a9ad',
        borderRadius: '0px',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#515869',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#515869',
    }),
  };

  return (
    <div className={`select-wrapper ${renderFormStyle(!!error)} form-text`}>
      <AsyncSelect
        cacheOptions
        loadOptions={loadProfessions}
        defaultOptions={defaultOptions}
        onChange={handleChange}
        value={selectedProfession}
        styles={customStyles}
        isClearable={true}
        placeholder={title}
        isDisabled={!!professionId}
      />
      {/* {error && <div className="form-error-msg">{error}</div>} */}
    </div>
  );
};

export { ProfessionSelector };
