import React from 'react';
import CheckImage from '@/assets/icons/check.png';
import CloseImage from '@/assets/icons/close.png';
import WarningImage from '@/assets/icons/warning.png';

export interface MessageProps {
  title: string;
  message: string;
  onClose: () => void;
  type: 'success' | 'error';
}

const PopupNotification: React.FC<MessageProps> = ({
  title,
  message,
  onClose,
  type,
}) => {
  const getImageByType = () => {
    switch (type) {
      case 'success':
        return CheckImage;
      case 'error':
        return WarningImage;
      default:
        return CheckImage;
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-20 z-20">
      <div className="min-w-[374px] h-[220px] lg:w-[540px] lg:h-[384px]">
        <div className="relative bg-white p-7 lg:p-10 rounded-lg flex flex-col items-center text-center">
          <img
            src={getImageByType()}
            alt={type}
            className="w-[40px] h-[40px] lg:w-16 lg:h-16 lg:mt-10"
          />
          <p className="text-[20px] lg:text-[38px] font-sans font-normal lg:mb-2 lg:py-5 leading-[60px] text-black">
            {title}
          </p>
          <p className="font-sans text-sm leading-[26px] lg:py-5 font-normal text-gray-900">
            {message}
          </p>
          <button className="absolute top-0 right-0 p-5" onClick={onClose}>
            <img src={CloseImage} alt="close" className="w-6 h-6" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupNotification;
