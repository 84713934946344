import { getBaseUrl } from '@/config/BaseUrl';

export default async function GetAllFAQListApi() {
  let res: string = '';
  try {
    const baseUrl = getBaseUrl();
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
    };
    await fetch(`${baseUrl}web/page-faq-list`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        console.error('Error getting frequent questions: ', error);
        res = error;
      });
  } catch (error) {
    console.error(error);
  }

  return res;
}
