import { useState } from 'react';
import PreferencesData from '@/components/home/preferences/PreferencesData';
import DivisionPreferences from '@/components/home/preferences/DivisionPreferences';
import JobTypePreferences from '@/components/home/preferences/JobTypePreferences';

export default function MobilePreferences({
  jobCountByState,
  jobCountByDivision,
  jobCountByJobType,
}) {
  const [selectedData, setSelectedData] = useState(jobCountByState);

  const handleDataSelection = (data) => {
    setSelectedData(data);
  };

  return (
    <section className="mobile-padding-container">
      <h1 className="section_header_text ">Jobs By Preferences</h1>
      <div className="mt-5">
        <div className="flex space-x-4 mb-4">
          <button
            onClick={() => handleDataSelection(jobCountByState)}
            className={`cursor-pointer preferences-topic ${
              selectedData === jobCountByState
                ? 'border border-blue-400 bg-gradient-to-r from-blue-500 to-blue-150 text-white py-2 px-7 2xl:py-6 2xl:px-20 rounded-md'
                : 'border-none py-3 px-7 2xl:py-6 2xl:px-20 rounded-md'
            }`}
          >
            State
          </button>
          <button
            onClick={() => handleDataSelection(jobCountByDivision)}
            className={`cursor-pointer preferences-topic ${
              selectedData === jobCountByDivision
                ? 'border border-blue-400 bg-gradient-to-r from-blue-500 to-blue-150 text-white py-2 px-7 2xl:py-6 2xl:px-20 rounded-md'
                : 'border-none py-3 px-7 2xl:py-6 2xl:px-20 rounded-md'
            }`}
          >
            Division
          </button>
          <button
            onClick={() => handleDataSelection(jobCountByJobType)}
            className={`cursor-pointer preferences-topic ${
              selectedData === jobCountByJobType
                ? 'border border-blue-400 bg-gradient-to-r from-blue-500 to-blue-150 text-white py-2 px-7 2xl:py-6 2xl:px-20 rounded-md'
                : 'border-none py-2 px-7 2xl:py-6 2xl:px-20 rounded-md'
            }`}
          >
            Job Type
          </button>
        </div>
        <div className="h-[370px] md:h-[200px]">
          {selectedData === jobCountByState && jobCountByState?.length > 0 && (
            <PreferencesData data={selectedData} selectedTopic={selectedData} />
          )}
          {selectedData === jobCountByDivision &&
            jobCountByDivision?.length > 0 && (
              <DivisionPreferences
                data={selectedData}
                selectedTopic={selectedData}
              />
            )}
          {selectedData === jobCountByJobType && (
            <JobTypePreferences
              data={selectedData}
              selectedTopic={selectedData}
            />
          )}
          {selectedData?.length === 0 && (
            <div>No data available for the selected preference.</div>
          )}
        </div>
      </div>
    </section>
  );
}
