import MobileSpecialtyData from './MobileSpecialtyData';
import { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function MobileSpecialty({ jobCountBySpecilaty }) {
  const [selectedDivisionId, setSelectedDivisionId] = useState(null);
  const [selectedDivisionSpecialties, setSelectedDivisionSpecialties] =
    useState([]);

  useEffect(() => {
    if (jobCountBySpecilaty && jobCountBySpecilaty?.length > 0) {
      setSelectedDivisionId(jobCountBySpecilaty[0].division_id);
    }
  }, [jobCountBySpecilaty]);

  useEffect(() => {
    if (selectedDivisionId && jobCountBySpecilaty?.length > 0) {
      const selectedDivision = jobCountBySpecilaty?.find(
        (division) => division.division_id === selectedDivisionId
      );
      if (selectedDivision) {
        const specialties = selectedDivision.specialities_with_job_count || [];
        setSelectedDivisionSpecialties(specialties);
      }
    }
  }, [selectedDivisionId, jobCountBySpecilaty]);

  const handleDivisionSelect = (divisionId) => {
    setSelectedDivisionId(divisionId);
  };

  const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="mobile-padding-container">
      <div className="flex items-center justify-between mb-5">
        <h2 className="section_header_text">Jobs By Specialty</h2>
      </div>
      <Slider {...sliderSettings}>
        {jobCountBySpecilaty?.map((division, index) => (
          <div
            key={index}
            onClick={() => handleDivisionSelect(division.division_id)}
            className={`cursor-pointer mb-10 preferences-topic ${
              selectedDivisionId === division.division_id
                ? 'bg-gradient-to-r from-blue-500 text-center transition duration-300 to-blue-150 border border-blue-400 text-white py-2 rounded-md'
                : 'border-none py-2 rounded-md text-center text-blue-700 hover:text-gray-120'
            }`}
          >
            <h3>{division.division_name}</h3>
          </div>
        ))}
      </Slider>
      <MobileSpecialtyData data={selectedDivisionSpecialties} />
    </section>
  );
}
