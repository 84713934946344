export default function calculateDaysDifference(dateString: string) {
  if (!dateString || typeof dateString !== 'string') {
    return '';
  }
  const [day, month, year] = dateString.split('-');
  const inputDate = new Date(`${year}-${month}-${day}`);

  const currentDate = new Date();

  const timeDifference = currentDate - inputDate;

  const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return dayDifference;
}
