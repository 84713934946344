import {
  Button,
  CommonMobileHero,
  DropDownSection,
  GratitudeComponent,
  HeroBackground,
  InsightComponent,
  MainJobCard,
  MobileJobSection,
  Register,
} from '@/components';
import { MobileLayout, PageLayout } from '@/layout';
import { useEffect, useState } from 'react';
import useResponsive from '@/hooks/useResponsive';
import MainMobileJobCard from '@/components/mobile/cards/MainMobileJobCard';
import { useLocation, useNavigate } from 'react-router-dom';
import useFetchAllProfessions from '@/components/forms/selectors/hook/useFetchAllProfessions';
import { formatURLNameToNormal } from '@/utils/formatNameUtil';
import PermanentJobFilter from '@/components/filter/PermanentJobFilter';
import LocumJobFilter from '@/components/filter/LocumJobFilter';
import FilterIcon from '/icons/Filter.png';
import NoJobsCard from '@/components/noData/NoJobsCard';
import LoadingComponent from '@/components/noData/LoadComponent';
import { formatNameForURL } from '@/utils/formatNameForURL';
import useFetchSpecialtyProfessionWise from '@/components/forms/selectors/hook/useFetchSpecialtyProfessionWise';
import useFetchSeniorityProfessionWise from '@/components/forms/selectors/hook/useFetchAllSeniorityProfessionWise';
import useFetchFilterData from '@/hooks/useFetchFilterMenuData';
import PageLoadingComponent from '@/components/noData/PageLoadingComponent';
import { motion } from 'framer-motion';
import ShareModal from '@/components/models/ShareModal';
import useFetchFilterForSpecialtyJobDetails from '@/hooks/useFetchFilterDataForSpecialty';
import NewCardComponent from '@/components/home/newBanner/NewCardComponent';
import { getProductionUrl } from '@/config/getProductionUrl';
import useFetchAllSeoDetails from '@/hooks/useFetchAllSeoDetails';
import SeoOptimized from '@/components/reactHelmet/SeoOptimized';
import { useStateContext } from '@/context/ContextProvider';
import MainMobileJobCardSkeleton from '@/components/noData/MobileLoadingComponent';
import useFetchBottomMenuData from '@/components/navbar/hook/useBottomNavBar';

const profFromLocalStorage = JSON.parse(
  localStorage.getItem('specPageProId') || 'null'
);

export default function AllJobsBySpecialty() {
  const CurLocation = useLocation();
  const matches = CurLocation.pathname.match(
    /\/specialty\/(permanent|locum)\/([a-z-&]+)-jobs-in-australia/i
  );
  const jobTypeFromURL = matches ? matches[1] : '';
  const specialty = matches ? matches[2] : '';
  const seniority = matches ? matches[2] : '';
  const productionUrl = getProductionUrl();
  const { seoData } = useFetchAllSeoDetails(
    `${productionUrl}${CurLocation.pathname}`
  );

  const navigate = useNavigate();
  const { isMobile, screenSize } = useResponsive();
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const [jobUrl, setJobUrl] = useState<string>('');
  const { allProfessionsData, professionDataLoading } =
    useFetchAllProfessions();
  const { bottomMenuData } = useFetchBottomMenuData();
  const { filterMenuData, filterMenuLoading } = useFetchFilterData();
  const [professionId, setProfessionId] =
    useState<number>(profFromLocalStorage);

  const [specialtyId, setSpecialtyId] = useState<number>();
  const [seniorityId, setSeniorityId] = useState<number>();
  const [stateId, setStateId] = useState<number>();
  const [suburbId, setSuburbId] = useState<number>();
  const [divisionId, setDivisionId] = useState<number>();
  const [regionId, setRegionId] = useState<number>();
  const { filterData, FilterDataLoading, FilterDataError } =
    useFetchFilterForSpecialtyJobDetails({
      divisionId,
      professionId,
      stateId,
      specialtyId,
      seniorityId,
      suburbId,
      regionId,
    });

  const [visibleJobs, setVisibleJobs] = useState<number>(6);
  const [selectedTopic, setSelectedTopic] = useState(
    jobTypeFromURL === 'permanent' ? 'Permanent' : 'Locum'
  );
  const { specialtiesProfessionWise, specialtyLoading } =
    useFetchSpecialtyProfessionWise(professionId);
  const { senioritiesProfessionWise, seniorityLoading } =
    useFetchSeniorityProfessionWise(professionId);
  const { isOffCanvasOpen, openOffCanvas } = useStateContext();
  const [description, setDescription] = useState('');
  const [mainTitle, setMainTitle] = useState('');

  const handleShareClick = (url: string) => {
    setJobUrl(url);
    setIsShareModalOpen(true);
  };

  const handleCloseShareModal = () => {
    setIsShareModalOpen(false);
  };

  const handleTopicClick = (selectedTopic) => {
    setSelectedTopic(selectedTopic);
    updateUrl(professionId, stateId, specialtyId, seniorityId, selectedTopic);
  };

  const handleProfessionChange = (professionId) => {
    setProfessionId(professionId);
    updateUrl(professionId, stateId, specialtyId, seniorityId, selectedTopic);
  };

  const handleStateChange = (stateId) => {
    setStateId(stateId);
    updateUrl(professionId, stateId, specialtyId, seniorityId, selectedTopic);
  };

  const handleSpecialtyChange = (specialtyId) => {
    setSpecialtyId(specialtyId);
    updateUrl(professionId, stateId, specialtyId, seniorityId, selectedTopic);
  };

  const handleSeniorityChange = (seniorityId) => {
    setSeniorityId(seniorityId);
    updateUrl(professionId, stateId, specialtyId, seniorityId, selectedTopic);
  };

  const handleSuburbChange = (suburbId) => {
    setSuburbId(suburbId);
  };

  const handleDivisionChange = (divisionId) => {
    setDivisionId(divisionId);
  };

  const handleRegionChange = (regionId) => {
    setRegionId(regionId);
  };
  // useEffect(() => {
  //   if (specialtyId !== undefined) {
  //     updateUrl(professionId, stateId, specialtyId, seniorityId, selectedTopic);
  //     const CheckedSpecialty = specialtiesProfessionWise?.find(
  //       (spec) => spec.specialities_id === specialtyId
  //     );

  //     const specialtyName = CheckedSpecialty?.short_name
  //       ? CheckedSpecialty?.short_name?.replace(/jobs/i, '').trim()
  //       : CheckedSpecialty?.name;

  //     if (specialtyName) {
  //       setMainTitle(CheckedSpecialty?.lp_title);
  //       setDescription(CheckedSpecialty?.description);
  //     }
  //   }
  // }, [specialtyId, specialtiesProfessionWise]);

  useEffect(() => {
    if (seniorityId !== undefined) {
      updateUrl(professionId, stateId, specialtyId, seniorityId, selectedTopic);
    }
  }, [seniorityId]);

  const updateUrl = (
    professionId,
    stateId,
    specialtyId,
    seniorityId,
    selectedTopic
  ) => {
    let formattedName;
    let path = `/specialty/${selectedTopic.toLowerCase()}`;
    const CheckedSpecialty = specialtiesProfessionWise?.find(
      (specialty) => specialty.specialities_id === specialtyId
    );
    const specialtyName = CheckedSpecialty?.short_name
      ? CheckedSpecialty?.short_name.replace(/jobs/i, '').trim()
      : CheckedSpecialty?.name;

    const seniorityName = senioritiesProfessionWise?.find(
      (seniority) => seniority.seniorities_id === seniorityId
    )?.name;
    const professionName = allProfessionsData?.find(
      (p) => p.profession_id === professionId
    )?.name;

    if (professionName) {
      formattedName = formatNameForURL(professionName);
    }

    if (specialtyName) {
      formattedName = formatNameForURL(specialtyName);
    }

    if (seniorityName) {
      formattedName = formatNameForURL(seniorityName);
    }

    if (formattedName) {
      path += `/${formattedName}-jobs-in-australia`;
    }

    navigate(path);
  };

  useEffect(() => {
    if (specialty && bottomMenuData) {
      bottomMenuData.forEach((division) => {
        division?.professions?.forEach((profession) => {
          profession?.specilities?.forEach((speciality) => {
            if (speciality.url === formatURLNameToNormal(specialty)) {
              setProfessionId(profession.profession_id);
              setSpecialtyId(speciality.specialities_id);
              setDescription(speciality.description);
              setMainTitle(speciality.lp_title);
              localStorage.setItem('specPageProId', profession.profession_id);
            }
          });
        });
      });
    }
  }, [specialty, bottomMenuData]);

  useEffect(() => {
    if (seniority) {
      const seniorityData = senioritiesProfessionWise?.find(
        (sen) => formatNameForURL(sen.name) === seniority
      );
      if (seniorityData) {
        setSeniorityId(seniorityData.seniorities_id);
        localStorage.setItem('specPageProId', seniorityData.profession_id);
      }
    }
  }, [seniority, senioritiesProfessionWise]);

  const filteredData = filterData?.filter((job) => {
    if (selectedTopic === 'Permanent') {
      return job.is_permanent === '1';
    } else {
      return job.is_permanent !== '1';
    }
  });

  if (filterMenuLoading || professionDataLoading) {
    return <PageLoadingComponent />;
  }

  if (FilterDataError) {
    return <div>Error: {FilterDataError.message}</div>;
  }

  const handleLoadMore = () => {
    setVisibleJobs((prevCount) => prevCount + 3);
  };

  const displayedJobs = filteredData?.slice(0, visibleJobs) || [];

  const jobCardVariants = {
    hidden: { x: -100, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: { type: 'spring', stiffness: 50, damping: 20, duration: 0.5 },
    },
  };

  return (
    <>
      {seoData && (
        <SeoOptimized
          title={seoData?.title}
          description={seoData?.description}
          robots={seoData?.robots}
          twitterImage={seoData?.twitterImage || seoData?.image}
          ogTitle={seoData?.og_title}
          ogDescription={seoData?.og_description}
          ogImage={seoData?.og_image}
          ogUrl={seoData?.og_URL}
          ogSiteName={seoData?.og_site_name}
          ogLocale={seoData?.og_locale}
          ogType={seoData?.og_type}
          canonicalUrl={seoData?.canonical}
        />
      )}
      {isMobile ? (
        <>
          <CommonMobileHero
            MainTitle={mainTitle}
            SubTitle={`${formatURLNameToNormal(specialty)} Jobs in australia`}
            Paragraph={''}
          />
          <MobileLayout>
            <div>
              <div className="py-[1.125rem] md:py-8">
                <div className="flex items-center justify-end">
                  <Button
                    type="button"
                    title="Filter & Sort"
                    variant="filter-btn"
                    iconSrc={FilterIcon}
                    handleClick={openOffCanvas}
                  />
                </div>
              </div>
              <div
                className={`absolute top-[65px] inset-0 z-[8] bg-gray-900 bg-opacity-50 ${isOffCanvasOpen ? 'block' : 'hidden'}`}
              >
                <div>
                  <div className="flex items-center justify-between">
                    <div className="w-[290px] md:w-[370px] lg:w-[480px] flex items-center shadow-md font-sans text-[20px] leading-[23px] lg:text-[26px] lg:leading-[32px] font-normal">
                      <h1
                        className={`w-1/2 text-center py-[14px] lg:py-5 ${
                          selectedTopic === 'Permanent'
                            ? 'bg-white'
                            : 'bg-gray-420'
                        } cursor-pointer`}
                        onClick={() => handleTopicClick('Permanent')}
                      >
                        Permanent
                      </h1>
                      <h1
                        className={`w-1/2 text-center py-[14px] lg:py-5 ${
                          selectedTopic === 'Locum' ? 'bg-white' : 'bg-gray-420'
                        } cursor-pointer`}
                        onClick={() => handleTopicClick('Locum')}
                      >
                        Locum
                      </h1>
                    </div>
                  </div>
                  <div className="flex items-center justify-start">
                    {selectedTopic === 'Permanent' ? (
                      <PermanentJobFilter
                        data={filterMenuData?.division}
                        allStates={filterMenuData?.state}
                        professionId={professionId}
                        stateId={stateId}
                        specialtyId={specialtyId}
                        seniorityId={seniorityId}
                        onProfessionChange={handleProfessionChange}
                        onSpecialtyChange={handleSpecialtyChange}
                        onSeniorityChange={handleSeniorityChange}
                        onStateChange={handleStateChange}
                        onSuburbSelect={handleSuburbChange}
                        suburbId={suburbId}
                        regionId={regionId}
                        divisionId={divisionId}
                        onDivisionChange={handleDivisionChange}
                        onRegionChange={handleRegionChange}
                      />
                    ) : (
                      <LocumJobFilter
                        data={filterMenuData?.division}
                        allStates={filterMenuData?.state}
                        professionId={professionId}
                        stateId={stateId}
                        specialtyId={specialtyId}
                        seniorityId={seniorityId}
                        onProfessionChange={handleProfessionChange}
                        onSpecialtyChange={handleSpecialtyChange}
                        onSeniorityChange={handleSeniorityChange}
                        onStateChange={handleStateChange}
                        onSuburbSelect={handleSuburbChange}
                        suburbId={suburbId}
                        regionId={regionId}
                        divisionId={divisionId}
                        onDivisionChange={handleDivisionChange}
                        onRegionChange={handleRegionChange}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {FilterDataLoading ? (
              <div className="flex items-center justify-center">
                <MainMobileJobCardSkeleton />
              </div>
            ) : (
              <div className="flex items-center justify-center gap-x-5">
                <div>
                  <div className="flex items-center justify-center">
                    {filteredData?.length === 0 ? (
                      <div className="">
                        <NoJobsCard selectedTopic={selectedTopic} />
                      </div>
                    ) : (
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-6">
                        {displayedJobs?.map((job, index) => (
                          <div key={index}>
                            <MainMobileJobCard data={job} />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="text-center my-10">
                    {filteredData?.length > visibleJobs && (
                      <button
                        onClick={handleLoadMore}
                        className="text-blue-390 text-center text-[20px] leading-[23px]"
                      >
                        Load More...
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </MobileLayout>
        </>
      ) : (
        <>
          <HeroBackground
            title={mainTitle}
            description={`${formatURLNameToNormal(specialty)} Jobs in australia`}
            icon={''}
          />
          <PageLayout>
            <div className="px-28">
              <div className="text-center font-sans font-normal text-[33px] lg:text-[24px] leading-[48px] lg:leading-[36px] text-blue-350 padding-container">
                {description}
              </div>
            </div>
            <div className="flex items-start justify-between gap-x-5 padding-container">
              <>
                <div className="">
                  <div className="">
                    <div className="flex items-center justify-between">
                      <div className="w-[370px] lg:w-[480px] bg-white p-2 flex items-center gap-x-2 shadow-md font-sans text-[20px] leading-[23px] lg:text-[26px] lg:leading-[32px] font-normal">
                        <h2
                          className={`w-1/2 text-center py-[14px] lg:py-3 bg-gray-30 rounded-md ${
                            selectedTopic === 'Permanent'
                              ? 'border-b-[4px] border-green-100'
                              : 'border-b-[4px] border-transparent'
                          } cursor-pointer`}
                          onClick={() => handleTopicClick('Permanent')}
                        >
                          Permanent
                        </h2>
                        <h2
                          className={`w-1/2 text-center py-[14px] lg:py-3 bg-gray-30 rounded-md ${
                            selectedTopic === 'Locum'
                              ? 'border-b-[4px] border-green-100'
                              : 'border-b-[4px] border-transparent'
                          } cursor-pointer`}
                          onClick={() => handleTopicClick('Locum')}
                        >
                          Locum
                        </h2>
                      </div>
                    </div>
                    <div className="flex items-center justify-start">
                      {selectedTopic === 'Permanent' ? (
                        <PermanentJobFilter
                          data={filterMenuData?.division}
                          allStates={filterMenuData?.state}
                          professionId={professionId}
                          stateId={stateId}
                          specialtyId={specialtyId}
                          seniorityId={seniorityId}
                          onProfessionChange={handleProfessionChange}
                          onSpecialtyChange={handleSpecialtyChange}
                          onSeniorityChange={handleSeniorityChange}
                          onStateChange={handleStateChange}
                          onSuburbSelect={handleSuburbChange}
                          suburbId={suburbId}
                          regionId={regionId}
                          divisionId={divisionId}
                          onDivisionChange={handleDivisionChange}
                          onRegionChange={handleRegionChange}
                        />
                      ) : (
                        <LocumJobFilter
                          data={filterMenuData?.division}
                          allStates={filterMenuData?.state}
                          professionId={professionId}
                          stateId={stateId}
                          specialtyId={specialtyId}
                          seniorityId={seniorityId}
                          onProfessionChange={handleProfessionChange}
                          onSpecialtyChange={handleSpecialtyChange}
                          onSeniorityChange={handleSeniorityChange}
                          onStateChange={handleStateChange}
                          onSuburbSelect={handleSuburbChange}
                          suburbId={suburbId}
                          regionId={regionId}
                          divisionId={divisionId}
                          onDivisionChange={handleDivisionChange}
                          onRegionChange={handleRegionChange}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
              <div>
                {FilterDataLoading ? (
                  <div className="flex items-center justify-center">
                    <LoadingComponent />
                  </div>
                ) : (
                  <div className="flex items-start justify-between gap-x-5">
                    <div>
                      <div className="">
                        {filteredData?.length === 0 ? (
                          <div className="">
                            <NoJobsCard selectedTopic={selectedTopic} />
                          </div>
                        ) : (
                          <div className="flex flex-col gap-y-6">
                            {displayedJobs.map((job, index) => (
                              <motion.div
                                key={index}
                                variants={jobCardVariants}
                                initial="hidden"
                                animate="visible"
                              >
                                <MainJobCard
                                  data={job}
                                  onShareClick={handleShareClick}
                                />
                              </motion.div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="text-center mt-10">
                        {filteredData?.length > visibleJobs && (
                          <button
                            onClick={handleLoadMore}
                            className="text-blue-390 text-center text-[20px] leading-[23px]"
                          >
                            Load More...
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <Register /> */}
            {/* <DropDownSection /> */}
          </PageLayout>
          <PageLayout>
            <GratitudeComponent
              mainTitle="Refer and Earn"
              subTitle="2500 AUD$"
            />
            <NewCardComponent />
          </PageLayout>
          {/* <PageLayout>
            <InsightComponent />
          </PageLayout> */}
        </>
      )}
      <div className={`modal ${isShareModalOpen ? 'modal-open' : ''}`}>
        <ShareModal
          isOpen={isShareModalOpen}
          onClose={handleCloseShareModal}
          jobUrl={jobUrl}
        />
      </div>
    </>
  );
}
