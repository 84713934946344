import { useEffect, useState } from 'react';
import CrossIcon from '@/assets/icons/CrossFilterIcon.png';
import AddIcon from '@/assets/icons/AddFilterIcon.png';
import FilterDownArrow from '/icons/filterArrow.png';

const LocumJobFilter = ({
  data,
  allStates,
  professionId,
  specialtyId,
  seniorityId,
  stateId,
  onProfessionChange,
  onSpecialtyChange,
  onSeniorityChange,
  onStateChange,
  onSuburbSelect,
  suburbId,
  regionId,
  divisionId,
  onDivisionChange,
  onRegionChange,
}) => {
  const [selectedDivisionId, setSelectedDivisionId] = useState(
    divisionId || null
  );
  const [selectedProfession, setSelectedProfession] = useState(
    professionId || null
  );
  const [selectedSpecialty, setSelectedSpecialty] = useState(
    specialtyId || null
  );
  const [selectedSeniority, setSelectedSeniority] = useState(
    seniorityId || null
  );
  const [selectedState, setSelectedState] = useState(stateId || null);
  const [selectedRegion, setSelectedRegion] = useState(regionId || null);
  const [selectedCity, setSelectedCity] = useState(suburbId || null);
  const [specialties, setSpecialties] = useState([]);
  const [seniorities, setSeniorities] = useState([]);
  const [openDivision, setOpenDivision] = useState(null);
  const [openState, setOpenState] = useState({});
  const [cities, setCities] = useState([]);
  console.log(openState);

  useEffect(() => {
    setSelectedProfession(professionId);
  }, [professionId]);

  useEffect(() => {
    setSelectedSpecialty(specialtyId);
  }, [specialtyId]);

  useEffect(() => {
    setSelectedSeniority(seniorityId);
  }, [seniorityId]);

  useEffect(() => {
    setSelectedState(stateId);
  }, [stateId]);

  useEffect(() => {
    setSelectedRegion(regionId);
  }, [stateId, regionId]);

  useEffect(() => {
    setSelectedDivisionId(divisionId);
  }, [divisionId]);

  useEffect(() => {
    setSelectedRegion(regionId);
  }, [regionId]);

  // useEffect(() => {
  //   setSelectedCity(selectedCity);
  // }, [suburbId]);

  useEffect(() => {
    setSelectedCity(selectedCity);
  }, [stateId, regionId, suburbId]);

  useEffect(() => {
    if (regionId && allStates) {
      const initialRegion = allStates
        .flatMap((state) => state.regions)
        .find((region) => region.region_id === regionId);
      if (initialRegion) {
        // setSelectedSuburb(initialRegion.suberb || []);
        setCities(initialRegion.suburb || []);
      }
    }
  }, [regionId, allStates]);

  useEffect(() => {
    if (selectedState && allStates) {
      const state = allStates.find((state) => state.state_id === selectedState);
      if (state && !selectedRegion) {
        setSelectedRegion(state.regions[0].region_id);
      }
    }
  }, [selectedState, allStates, selectedRegion]);

  useEffect(() => {
    if (selectedProfession && data) {
      const divisionWithProfession = data.find((division) =>
        division.professions.some(
          (profession) => profession.profession_id === selectedProfession
        )
      );

      const profession = data.find((division) =>
        division.professions.some((p) => p.profession_id === selectedProfession)
      );
      if (profession) {
        setOpenDivision(profession.division_id);
        const professionData = profession.professions.find(
          (p) => p.profession_id === selectedProfession
        );
        setSpecialties(professionData.specialities || []);
        setSeniorities(professionData.seniorities || []);
      }

      if (divisionWithProfession) {
        setOpenDivision(divisionWithProfession.division_id);
        setSelectedDivisionId(divisionWithProfession.division_id);
        onDivisionChange(divisionWithProfession.division_id);
      }
    }
  }, [selectedProfession, data]);

  const handleDivisionToggle = (divisionId) => {
    setOpenDivision(openDivision === divisionId ? null : divisionId);
  };

  const handleDivisionChange = (divisionId) => {
    setSelectedDivisionId(divisionId);
    onDivisionChange(divisionId);

    if (selectedProfession) {
      const relatedProfession = data.find((division) =>
        division.professions.some(
          (profession) =>
            profession.profession_id === selectedProfession &&
            division.division_id === divisionId
        )
      );
      if (!relatedProfession) {
        setSelectedProfession(null);
      }
    }
  };

  useEffect(() => {
    if (regionId && allStates) {
      const stateContainingRegion = allStates.find((state) =>
        state.regions.some((region) => region.region_id === regionId)
      );

      if (stateContainingRegion) {
        setSelectedState(stateContainingRegion.state_id);
        setOpenState((prevState) => ({
          ...prevState,
          [stateContainingRegion.state_id]: true,
        }));
      }

      setSelectedRegion(regionId);
      setOpenState((prevState) => ({
        ...prevState,
        [stateContainingRegion.state_id]: true,
      }));
    }
  }, [regionId, allStates]);

  const handleProfessionChange = (professionId) => {
    setSelectedProfession(professionId);
    onProfessionChange(professionId);
  };

  const handleSpecialtyChange = (specialtyId) => {
    setSelectedSpecialty(specialtyId);
    onSpecialtyChange(specialtyId);
  };

  const handleSeniorityChange = (seniorityId) => {
    setSelectedSeniority(seniorityId);
    onSeniorityChange(seniorityId);
  };

  const handleStateChange = (stateId) => {
    setSelectedState(stateId);
    onStateChange(stateId);
    setSelectedRegion(null);
    setSelectedCity(null);
  };

  const handleRegionChange = (regionId) => {
    setSelectedRegion(regionId);
    onRegionChange(regionId);

    const relatedState = allStates?.find((state) =>
      state.regions.some((region) => region.region_id === regionId)
    );

    if (relatedState) {
      setSelectedState(relatedState.state_id);
      onStateChange(relatedState.state_id);
    }

    const selectedRegionData = allStates
      .flatMap((state) => state.regions)
      .find((region) => region.region_id === regionId);
    if (selectedRegionData) {
      setCities(selectedRegionData.suburb);
    }
  };

  const handleSuburbClick = (suburbId) => {
    setSelectedCity(suburbId);
    onSuburbSelect(suburbId);
  };

  // useEffect(() => {
  //   const initialOpenState = allStates?.reduce((acc, state) => {
  //     acc[state.state_id] = false;
  //     return acc;
  //   }, {});
  //   setOpenState(initialOpenState);
  // }, [allStates]);

  useEffect(() => {
    if (allStates && Array.isArray(allStates)) {
      const initialOpenState = allStates?.reduce((acc, state) => {
        acc[state.state_id] = false;
        return acc;
      }, {});
      setOpenState(initialOpenState);
    }
  }, [allStates]);

  return (
    <div className="bg-white shadow-md rounded-b-md w-[290px] md:w-[370px] p-2 px-3 lg:w-[480px]">
      <div className=" p-3 rounded-md">
        <div className="flex items-center justify-between bg-gray-30 p-2 py-3 rounded-md mb-3">
          <h2 className="font-sans font-bold text-lg lg:text-[24px] leading-[24px] text-blue-700 ">
            Professions
          </h2>
          <div className="w-[15px] h-[15px]">
            <img src={FilterDownArrow} className="object-cover object-center" />
          </div>
        </div>
        {data?.map((division) => (
          <div key={division.division_id}>
            <div
              onClick={() => handleDivisionToggle(division.division_id)}
              className="mb-1"
            >
              <div className="flex items-center justify-between py-[3px]">
                <div className="flex items-center">
                  <input
                    className="radio-btn"
                    type="radio"
                    id={`division-${division.division_id}`}
                    name="division"
                    value={division.division_id}
                    checked={selectedDivisionId === division.division_id}
                    onChange={() => handleDivisionChange(division.division_id)}
                  />
                  <h3 className="font-sans font-normal text-sm lg:text-[18px] leading-[28px] text-blue-700 ml-3 cursor-pointer">
                    {division.division_name}
                  </h3>
                </div>
                <div className="w-[36px] h-[36px] rounded-full bg-green-200 text-white font-sans font-normal text-xs flex items-center justify-center">
                  {division.job_count}
                </div>
              </div>
            </div>
            {openDivision === division.division_id && (
              <div>
                {division.professions?.map((profession) => (
                  <div
                    key={profession.profession_id}
                    className="ml-4 font-sans font-normal text-sm lg:text-[18px] leading-[30px] flex items-center justify-between"
                  >
                    <div className="flex items-center">
                      <input
                        className="radio-btn"
                        type="radio"
                        id={`profession-${profession.profession_id}`}
                        name="profession"
                        value={profession.profession_id}
                        checked={
                          selectedProfession === profession.profession_id
                        }
                        onChange={() =>
                          handleProfessionChange(profession.profession_id)
                        }
                      />
                      <label
                        className="ml-3 cursor-pointer my-1"
                        htmlFor={`profession-${profession.profession_id}`}
                      >
                        {profession.profession_name}
                      </label>
                    </div>
                    <div className="w-[36px] h-[36px] rounded-full bg-green-200 text-white font-sans font-normal text-xs flex items-center justify-center">
                      {profession.job_count}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      {selectedProfession ? (
        <>
          <div className="p-3 rounded-md">
            <div className="flex items-center justify-between bg-gray-30 p-2 py-3 rounded-md mb-4">
              <h2 className="font-sans font-bold text-lg lg:text-[24px] leading-[24px] text-blue-700 ">
                Specialty
              </h2>
              <div className="flex items-center justify-normal gap-x-2">
                <button
                  className="ml-2 text-sm text-gray-500"
                  onClick={() => handleSpecialtyChange(null)}
                >
                  Clear
                </button>
                <div className="w-[15px] h-[15px]">
                  <img
                    src={FilterDownArrow}
                    className="object-cover object-center"
                  />
                </div>
              </div>
            </div>
            {specialties.length > 0 ? (
              specialties.map((specialty) => (
                <div
                  key={specialty.speciality_id}
                  className="font-sans font-normal text-sm lg:text-[18px] leading-[28px] text-blue-700 flex items-center justify-between py-[2px]"
                >
                  <div className="flex items-center">
                    <input
                      className="radio-btn"
                      type="radio"
                      id={`specialty-${specialty.speciality_id}`}
                      name="specialty"
                      value={specialty.speciality_id}
                      checked={selectedSpecialty === specialty.speciality_id}
                      onChange={() =>
                        handleSpecialtyChange(specialty.speciality_id)
                      }
                    />
                    <label
                      htmlFor={`specialty-${specialty.speciality_id}`}
                      className="ml-3 cursor- my-1"
                    >
                      {specialty.speciality_name}
                    </label>
                  </div>
                  <div className="w-[30px] h-[30px] rounded-full bg-green-200 text-white font-sans font-normal text-xs flex items-center justify-center">
                    {specialty.job_count}
                  </div>
                </div>
              ))
            ) : (
              <p>No specialties available</p>
            )}
          </div>
          <div className="p-3 rounded-md">
            <div className="flex items-center justify-between bg-gray-30 p-2 py-3 rounded-md mb-4">
              <h2 className="font-sans font-bold text-lg lg:text-[24px] leading-[24px] text-blue-700 ">
                Seniority
              </h2>
              <div className="flex items-center justify-normal gap-x-2">
                <button
                  className="ml-2 text-sm text-gray-500"
                  onClick={() => handleSpecialtyChange(null)}
                >
                  Clear
                </button>
                <div className="w-[15px] h-[15px]">
                  <img
                    src={FilterDownArrow}
                    className="object-cover object-center"
                  />
                </div>
              </div>
            </div>
            {seniorities.length > 0 ? (
              seniorities.map((seniority) => (
                <div
                  key={seniority.seniority_id}
                  className="font-sans font-normal text-sm lg:text-[18px] leading-[28px] text-blue-700 flex items-center justify-between py-[2px]"
                >
                  <div className="flex items-center">
                    <input
                      className="radio-btn"
                      type="radio"
                      id={`seniority-${seniority.seniority_id}`}
                      name="seniority"
                      value={seniority.seniority_id}
                      checked={selectedSeniority === seniority.seniority_id}
                      onChange={() =>
                        handleSeniorityChange(seniority.seniority_id)
                      }
                    />
                    <label
                      htmlFor={`seniority-${seniority.seniority_id}`}
                      className="ml-3 cursor-pointer my-1"
                    >
                      {seniority.seniority_name}
                    </label>
                  </div>
                  <div className="w-[30px] h-[30px] rounded-full bg-green-200 text-white font-sans font-normal text-xs flex items-center justify-center">
                    {seniority.job_count}
                  </div>
                </div>
              ))
            ) : (
              <p>No seniorities available</p>
            )}
          </div>
        </>
      ) : (
        <div className="my-5 lg:my-2">
          <div className=" my-5 lg:my-2 p-3 rounded-md">
            <div className="flex items-center justify-between bg-gray-30 p-2 py-3 rounded-md mb-4">
              <h2 className="font-sans font-bold text-lg lg:text-[24px] leading-[24px] text-blue-700 ">
                Specialty
              </h2>
              <img src={FilterDownArrow} width={15} height={19} />
            </div>
            <p className="p-2 text-gray-480">
              Please select a profession to check specialties.
            </p>
          </div>
          <div className="p-3 rounded-md">
            <div className="flex items-center justify-between bg-gray-30 p-2 py-3 rounded-md mb-4">
              <h2 className="font-sans font-bold text-lg lg:text-[24px] leading-[24px] text-blue-700 ">
                Seniority
              </h2>
              <img src={FilterDownArrow} width={15} height={19} />
            </div>
            <p className="p-2 text-gray-480">
              Please select a profession to check specialties.
            </p>
          </div>
        </div>
      )}

      <div className="p-3 rounded-md">
        <div className="flex items-center justify-between bg-gray-30 p-2 py-3 rounded-md mb-4">
          <h2 className="font-sans font-bold text-lg lg:text-[24px] leading-[24px] text-blue-700 ">
            Location
          </h2>
          <div className="flex items-center justify-normal gap-x-2">
            <button
              className="ml-2 text-sm text-gray-500"
              onClick={() => handleSpecialtyChange(null)}
            >
              Clear
            </button>
            <img src={FilterDownArrow} width={15} height={19} />
          </div>
        </div>
        <div>
          {allStates && allStates.length > 0 ? (
            allStates.map((state) => (
              <div
                key={state.state_id}
                className="font-sans font-normal text-sm lg:text-[18px] leading-[28px] text-blue-700"
              >
                <div className="flex items-center justify-between mb-1">
                  <div className="py-[3px] flex items-center">
                    <input
                      className="radio-btn"
                      type="radio"
                      id={`state-${state.state_id}`}
                      name="state"
                      value={state.state_id}
                      checked={selectedState === state.state_id}
                      onChange={() => handleStateChange(state.state_id)}
                    />
                    <label
                      htmlFor={`state-${state.state_id}`}
                      className="ml-3 cursor-pointer my-1"
                    >
                      {state.state_name}
                    </label>
                  </div>
                  <div className="flex items-center gap-x-5">
                    <div className="w-[30px] h-[30px] rounded-full bg-green-200 text-white font-sans font-normal text-xs flex items-center justify-center">
                      {state.job_count}
                    </div>
                    <div className="w-[27px] h-[27px] rounded-full bg-gray-420 flex items-center justify-center cursor-pointer">
                      <img
                        src={openState[state.state_id] ? CrossIcon : AddIcon}
                        className="w-[15px] h-[15px] object-cover"
                        onClick={() =>
                          setOpenState({
                            ...openState,
                            [state.state_id]: !openState[state.state_id],
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                {openState[state.state_id] && (
                  <div className="ml-8">
                    {state.regions.map((region) => (
                      <div key={region.region_id}>
                        <div className="">
                          <div className="py-[3px] flex items-center justify-between">
                            <div className="flex items-center">
                              <input
                                className="radio-btn"
                                type="radio"
                                id={`region-${region.region_id}`}
                                name="region"
                                value={region.region_id}
                                checked={selectedRegion === region.region_id}
                                onChange={() =>
                                  handleRegionChange(region.region_id)
                                }
                              />
                              <label
                                htmlFor={`region-${region.region_id}`}
                                className="ml-4 cursor-pointer text-base my-1"
                              >
                                {region.region_name}
                              </label>
                            </div>
                            <div className="w-[30px] h-[30px] rounded-full bg-green-200 text-white font-sans font-normal text-xs flex items-center justify-center">
                              {region.job_count}
                            </div>
                          </div>
                          {selectedRegion === region.region_id && (
                            <div className="grid grid-cols-3 gap-4 my-3">
                              {region.suburbs?.map((city) => (
                                <div key={city.suburb_id} className="">
                                  <button
                                    className={`w-[130px] text-xs lg:text-base py-4 text-center text-wrap cursor-pointer rounded-md focus:outline-none ${
                                      selectedCity === city.suburb_id
                                        ? 'bg-gradient-to-r from-blue-100 to-blue-150 text-white'
                                        : 'bg-gray-200'
                                    }`}
                                    onClick={() =>
                                      handleSuburbClick(city.suburb_id)
                                    }
                                  >
                                    {city.suburb_name} {city.job_count}
                                  </button>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))
          ) : (
            <p>No states available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LocumJobFilter;
