import React from 'react';
import Rating from '../shared/rating/Rating';
import ComaImages from '/icons/feedbackTop.png';

interface FeedBackProps {
  feedback: {
    id: number;
    comment: string;
    user: string;
    rating: number;
    url?: string; // Made url optional to handle missing images
  };
  isActive: boolean;
}

const FeedbackComponent: React.FC<FeedBackProps> = ({ feedback, isActive }) => {
  // Function to get initials from the user's name
  const getInitials = (name: string) => {
    const [firstName, lastName] = name.split(' ');
    return `${firstName[0]}${lastName ? lastName[0] : ''}`.toUpperCase();
  };

  return (
    <div className={`feedback-card ${isActive ? 'active' : ''}`}>
      <div className="flex flex-col items-center justify-center">
        <div className="w-[19px] h-[19px]">
          <img src={ComaImages} className="w-full h-full " />
        </div>
        <Rating maxRating={5} rating={feedback.rating} />
      </div>
      <p className="font-sans text-base text-center leading-[29px] text-gray-360 line-clamp-5">
        {feedback.comment}
      </p>
      <div className="avatar flex flex-col items-center text-center">
        {feedback.url ? (
          <img
            className="w-10 h-10 rounded-full mb-3"
            src={feedback.url}
            alt={`${feedback.user}'s avatar`}
          />
        ) : (
          <div className="w-10 h-10 rounded-full mb-3 bg-blue-50 border border-blue-400 flex items-center justify-center text-blue-260 font-bold">
            {getInitials(feedback.user)}
          </div>
        )}
        <p className="font-sans font-semibold text-base leading-[24px] text-gray-800 text-nowrap">
          {feedback.user}
        </p>
      </div>
    </div>
  );
};

export default FeedbackComponent;
