import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export default function NewCardComponent() {
  const images = [
    {
      src: '/images/PremierGP.png',
      alt: 'Image 1',
      route: '/premier-locum-general-practitioner',
      top: { default: '5rem', xl: '3.75rem', lg: '4.375rem', md: '3.75rem' },
      left: { default: '30rem', xl: '25rem', lg: '21.875rem', md: '18.125rem' },
      width: {
        default: '18.3125rem',
        xl: '16.4375rem',
        lg: '14.375rem',
        md: '10rem',
      },
      height: {
        default: '20.625rem',
        xl: '20rem',
        lg: '18.75rem',
        md: '17.5rem',
      },
      rotate: 2,
    },
    {
      src: '/images/PremierDoctor.png',
      alt: 'Image 2',
      route: '/maintenance',
      top: {
        default: '6.875rem',
        xl: '6.25rem',
        lg: '6.25rem',
        md: '5.625rem',
      },
      left: {
        default: '43.75rem',
        xl: '37.5rem',
        lg: '32.8125rem',
        md: '27.5rem',
      },
      width: {
        default: '18.3125rem',
        xl: '16.4375rem',
        lg: '14.375rem',
        md: '10rem',
      },
      height: {
        default: '20.625rem',
        xl: '20rem',
        lg: '18.75rem',
        md: '17.5rem',
      },
      rotate: -1,
    },
    {
      src: '/images/WorkForce.png',
      alt: 'Image 3',
      route: '/maintenance',
      top: { default: '5rem', xl: '4.375rem', lg: '4.375rem', md: '3.75rem' },
      left: {
        default: '57.5rem',
        xl: '50rem',
        lg: '43.75rem',
        md: '36.875rem',
      },
      width: {
        default: '18.3125rem',
        xl: '16.4375rem',
        lg: '14.375rem',
        md: '10rem',
      },
      height: {
        default: '20.625rem',
        xl: '20rem',
        lg: '18.75rem',
        md: '17.5rem',
      },
      rotate: 2,
    },
    {
      src: '/images/Nurse.png',
      alt: 'Image 4',
      route: '/maintenance',
      top: { default: '3.75rem', xl: '3.125rem', lg: '5.625rem', md: '5rem' },
      left: {
        default: '71.25rem',
        xl: '62.5rem',
        lg: '54.6875rem',
        md: '46.125rem',
      },
      width: {
        default: '18.3125rem',
        xl: '16.4375rem',
        lg: '14.375rem',
        md: '10rem',
      },
      height: {
        default: '20.625rem',
        xl: '20rem',
        lg: '18.75rem',
        md: '17.5rem',
      },
      rotate: -1,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const getPosition = (position) => {
    const { default: defaultPos, xl, lg, md } = position;
    if (window.innerWidth >= 1920) return defaultPos;
    if (window.innerWidth >= 1536) return xl;
    if (window.innerWidth >= 1280) return lg;
    if (window.innerWidth >= 1024) return md;
    return defaultPos;
  };

  return (
    <div className="flex items-center justify-center padding-container">
      <div className="main-width-container relative lg:h-[380px] xl:h-[430px] 2xl:h-[500px] bg-white flex items-center justify-between lg:px-5 xl:px-10 4xl:px-20 custom-shadow-360">
        <p className="font-sans lg:text-[28px] 2xl:text-[34px] 4xl:text-[42px] 4xl:leading-[55px] text-blue-600 lg:w-[300px] 2xl:w-[423px]">
          Explore our shift for becoming the medfuture workforce
        </p>
        <div>
          {images.map((image, index) => (
            <Link to={image.route} key={index}>
              <motion.div
                initial={{ opacity: 0.95, scale: 0.8 }}
                animate={{
                  opacity: index === currentIndex ? 1 : 0.95,
                  scale: index === currentIndex ? 1.0 : 0.95,
                }}
                transition={{ duration: 1 }}
                style={{
                  position: 'absolute',
                  top: getPosition(image.top),
                  left: getPosition(image.left),
                  width: getPosition(image.width),
                  height: getPosition(image.height),
                }}
                className={`responsive-image-container cursor-pointer image-${index}`}
              >
                <motion.img
                  src={image.src}
                  alt={image.alt}
                  className="w-full h-full object-cover"
                  whileHover={{
                    scale: 1.03,
                    transition: { duration: 1 },
                  }}
                />
              </motion.div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
