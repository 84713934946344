import shareIcon from '@/assets/icons/MainShare.png';
import Button from '@/components/shared/button/Button';
import applyNow from '@/assets/icons/ApplyNow.png';
import { useNavigate } from 'react-router-dom';
import { AWS_BASE_ASSETS_ICONS } from '@/config/seviceApiConfig';
import { formatJobNameForURL } from '@/utils/formatJobNameforURL';
import calculateDaysDifference from '@/utils/getNoOfDatesUtil';
import { getDisplayText } from '@/utils/handleUndefinedUtil';

export default function MainJobCard({ data, onShareClick }) {
  const navigate = useNavigate();

  const handleApplyNow = (event) => {
    // localStorage.setItem('jobData', JSON.stringify(data));
    event.stopPropagation();
    const jobUrl = formatJobNameForURL(data.job_title);
    if (data.is_permanent === '1') {
      navigate(`/apply-now/permanent/${jobUrl}/${data.jobdetails_id}`, {
        state: { jobData: data },
      });
    } else {
      navigate(`/apply-now/locum/${jobUrl}/${data.jobdetails_id}`, {
        state: { jobData: data },
      });
    }
    window.scrollTo(0, 0);
  };

  const handleShareButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    const jobUrl = formatJobNameForURL(data.job_title);
    const shareUrl =
      data.is_permanent === '1'
        ? `${window.location.origin}/apply-now/permanent/${jobUrl}/${data.jobdetails_id}`
        : `${window.location.origin}/apply-now/locum/${jobUrl}/${data.jobdetails_id}`;
    onShareClick(shareUrl);
  };

  return (
    <>
      <div
        className="bg-white shadow-md max-w-[980px] h-auto p-4 border-[2px] border-gray-320 rounded-md relative cursor-pointer"
        onClick={handleApplyNow}
      >
        <div className="p-3 rounded-[10px]">
          <div className="flex items-center justify-between">
            <h3 className="text-gray-480 font-semibold bg-gray-30 border rounded-md border-gray-120 px-[6px] text-sm">
              {data.job_id}
            </h3>
            <div className="cursor-pointer" onClick={handleShareButtonClick}>
              <img src={shareIcon} className="cursor-pointer" />
            </div>
          </div>
          <h3 className="font-sans font-bold text-[28px] leading-[38px] text-blue-700 4xl:min-w-[400px]  mt-2">
            {data.job_title}
          </h3>
          <div className="flex items-center mt-2">
            <div className="h-[30px] w-[30px] rounded-full flex items-center justify-center mr-2">
              <img
                src={`${AWS_BASE_ASSETS_ICONS}mainLocation.png`}
                alt="Salary Icon"
                className="w-[16px] h-[19px] object-contain"
              />
            </div>
            <p className="text-gray-700 text-base">
              {getDisplayText(data.suburb?.name, data.state?.name)}
            </p>
          </div>
          {/* <div className="flex items-center justify-between">
            <div className="flex items-center justify-normal mb-1">
              <h3 className="card-text-main mr-4">{data.profession?.name}</h3>
              <h3 className="text-gray-480 font-semibold bg-gray-20 border rounded-md border-gray-120 px-[6px] text-sm">
                {data.job_id}
              </h3>
            </div>
          </div> */}
          {/* <div className="flex items-end justify-between">
            <div className="grid grid-cols-2 gap-x-20">
              <div className="flex items-center my-1">
                <div className="h-[30px] w-[30px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
                  <img
                    src={`${AWS_BASE_ASSETS_ICONS}CurrencyIcon.png`}
                    alt="Salary Icon"
                    className="w-[14.8px] h-[14.8px] object-contain"
                  />
                </div>
                {data.billing_share && data.hourly_fee ? (
                  <p className="line-clamp-1">{`${data.hourly_fee} or ${data.billing_share}`}</p>
                ) : (
                  <p className="line-clamp-1">
                    {data.hourly_fee || data.billing_share}
                  </p>
                )}
              </div>
              <div className="flex items-center my-1">
                <div className="h-[30px] w-[30px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
                  <img
                    src={`${AWS_BASE_ASSETS_ICONS}LocationIcon.png`}
                    alt="Salary Icon"
                    className="w-[14.8px] h-[15.3px] object-contain"
                  />
                </div>
                <p>{`${data.suburb?.name}, ${data.state?.name}`}</p>
              </div>
              <div className="flex items-center  my-1">
                <div className="h-[30px] w-[30px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
                  <img
                    src={`${AWS_BASE_ASSETS_ICONS}ClockIcon.png`}
                    alt="Salary Icon"
                    className="w-[14.8px] h-[14.8px] object-contain "
                  />
                </div>
                <p>{data.engagement_type?.name}</p>
              </div>
              <div className="flex items-center  my-1">
                <div className="h-[30px] w-[30px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
                  <img
                    src={`${AWS_BASE_ASSETS_ICONS}JobTypeIcon.png`}
                    alt="Salary Icon"
                    className="w-[13.34px] h-[12.13px] object-contain"
                  />
                </div>
                <p>{data.is_permanent === '1' ? 'Permanent' : 'Locum'}</p>
              </div>
            </div>
            <div className="cursor-pointer" onClick={handleShareButtonClick}>
              <img src={shareIcon} className="cursor-pointer" />
            </div>
          </div> */}
        </div>
        <div className="px-3">
          {/* <h3 className="font-sans font-normal text-[28px] leading-[34px] text-blue-700 4xl:min-w-[920px] line-clamp-1">
            {data.job_title}
          </h3> */}
          <p className="font-satoshi text-base leading-[25px] font-normal text-gray-700 line-clamp-3">
            {data.job_brief}
          </p>
        </div>
        <div className="flex items-center justify-between mt-4 px-3">
          <p className="text-gray-700 text-base">
            Posted {calculateDaysDifference(data.commencement_date)} days ago
          </p>
          <div className="w-[182px]">
            <Button
              type="submit"
              title="View"
              variant="find-jobs-main-btn"
              iconSrc={applyNow}
              handleClick={handleApplyNow}
            />
          </div>
        </div>
      </div>
    </>
  );
}
