import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BannerDataProps, BannerItem } from './types'; // Assuming you have a type for BannerItem
import BlurryLoadingImage from '../../../hooks/useFetchImage';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useResponsive from '@/hooks/useResponsive';
import { AWS_BASE_URL_ASSETS } from '@/config/seviceApiConfig';

const BannerData: React.FC<BannerDataProps> = ({ data }) => {
  const { isMobile } = useResponsive();
  const location = useLocation();

  const settings: Settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: false,
    touchMove: true,
    className: 'custom-carousel',
    appendDots: (dots: JSX.Element[]) => (
      <div className="mobile-slick-dots">
        {dots.slice(0, 4).map((dot, index) => (
          <div
            key={index}
            className="inline-block m-[5px] cursor-pointer w-[11px] h-[11px] rounded-full"
          >
            {dot}
          </div>
        ))}
      </div>
    ),
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {!isMobile ? (
        <div className="grid grid-cols-3 gap-4 cursor-pointer">
          {data.map((item: BannerItem) => (
            <Link to={`${item.route}`} key={item.id}>
              <BlurryLoadingImage
                preview={`${AWS_BASE_URL_ASSETS}${item.url}`}
                image={`${AWS_BASE_URL_ASSETS}${item.url}`}
                alt={item.title}
                imageStyleClass=""
                divStyleClass="banner-container"
                bgColor={''}
              />
            </Link>
          ))}
        </div>
      ) : (
        <Slider {...settings}>
          {data.map((item: BannerItem) => (
            <Link key={item.id} to={item.route}>
              <BlurryLoadingImage
                preview={`${AWS_BASE_URL_ASSETS}${item.url}`}
                image={`${AWS_BASE_URL_ASSETS}${item.url}`}
                alt={item.title}
                imageStyleClass=""
                divStyleClass="banner-container"
                bgColor={''}
              />
            </Link>
          ))}
        </Slider>
      )}
    </>
  );
};

export default BannerData;
