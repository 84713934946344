export default function NoJobsCard({ selectedTopic }) {
  const message =
    selectedTopic === 'Permanent'
      ? 'Currently Permanent Jobs are not available'
      : 'Currently Locum Jobs are not available';

  return (
    <div className="bg-white max-w-[800px] px-14 lg:px-24 text-center">
      <h5 className="text-blue-500 font-bold text-[18px] lg:text-[40px] lg:text-center">
        {message}
      </h5>
      <p className="text-blue-500 text-xs lg:text-lg mt-4">
        Please check back later for exciting opportunities!
      </p>
    </div>
  );
}
