import GetAllFAQListApi from '@/api/GetAllQuestionsApi';
import { useEffect, useState } from 'react';

interface FAQ {
  faq_id: number;
  page_id: number;
  question: string;
  answer: string;
  status: string;
  created_by: number | null;
  updated_by: number | null;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
}

interface PageFAQ {
  pages_id: number;
  name: string;
  page_type: string;
  page_url: string;
  description: string | null;
  status: string;
  created_by: number | null;
  updated_by: number | null;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
  faqs: FAQ[] | null;
}

interface FetchAllFAQData {
  allFaqData: PageFAQ[] | null;
  loading: boolean;
  error: Error | null;
}

export default function useFetchFrequentQuestions(): FetchAllFAQData {
  const [allFaqData, setAllFaqData] = useState<FAQ[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await GetAllFAQListApi();
      setAllFaqData(response);
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { allFaqData, loading, error };
}
