import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const MainMobileJobCardSkeleton = () => {
  return (
    <div className="bg-white rounded-md drop-shadow-lg p-4 w-[307px] h-[340px] mx-1 mt-2 mb-4">
      <Skeleton height={30} width={200} className="mb-2" />
      <Skeleton height={20} width={100} className="mb-2" />
      <Skeleton height={10} width={150} className="mb-2" />

      <div className="flex items-center my-1">
        <div className="h-[28px] w-[28px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
          <Skeleton circle={true} height={28} width={28} />
        </div>
        <Skeleton width={100} />
      </div>

      <div className="flex items-center my-1">
        <div className="h-[28px] w-[28px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
          <Skeleton circle={true} height={28} width={28} />
        </div>
        <Skeleton width={120} />
      </div>

      <div className="flex items-center my-1">
        <div className="h-[28px] w-[28px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
          <Skeleton circle={true} height={28} width={28} />
        </div>
        <Skeleton width={100} />
      </div>

      <div className="flex items-center my-1">
        <div className="h-[28px] w-[28px] rounded-full bg-blue-50 flex items-center justify-center mr-2">
          <Skeleton circle={true} height={28} width={28} />
        </div>
        <Skeleton width={80} />
      </div>

      <div className="mt-5">
        <Skeleton height={40} width={120} />
      </div>
    </div>
  );
};

export default MainMobileJobCardSkeleton;
