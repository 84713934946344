import React from 'react';
import { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface SlickSettingsProps {
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const slickSettings = ({
  activeIndex,
  setActiveIndex,
}: SlickSettingsProps): Settings => {
  const handleBeforeChange = (_: any, next: number) => {
    setActiveIndex(next);
  };

  return {
    dots: true,
    infinite: false,
    speed: 100,
    slidesToShow: 4,
    slidesToScroll: 4,
    swipe: false,
    autoplay: false,
    touchMove: true,
    className: 'custom-carousel',
    appendDots: (dots: JSX.Element[]) => (
      <div className="slick-dots">
        {dots.slice(0, 3).map((dot, index) => (
          <div
            key={index}
            className="inline-block m-[10px] cursor-pointer w-[0.813rem] h-[0.813rem] rounded-full"
          >
            {dot}
          </div>
        ))}
      </div>
    ),
    beforeChange: handleBeforeChange,

    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  };
};
