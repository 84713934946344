import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GetAllSeoDetailsApi from '@/api/seo/GetAllSeoDetailsApi';

interface Seo {
  seo_id: number;
  name: string;
  pages_id: string;
  description: string;
  title: string;
  canonical: string;
  robots: string;
  google_site_verification?: string | null;
  og_local: string;
  og_type: string;
  og_title: string;
  og_URL: string;
  og_site_name: string;
  image: string;
  alt: string;
  status: number;
  created_by: string;
  updated_by: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string | null;
}

interface FetchSeoData {
  seoData: Seo[] | null;
  seoDataLoading: boolean;
  seoDataError: Error | null;
}

export default function useFetchAllSeoDetails(url: string): FetchSeoData {
  const navigate = useNavigate();
  const [seoData, setSeoData] = useState<Seo[] | null>(null);
  const [seoDataLoading, setLoading] = useState<boolean>(true);
  const [seoDataError, setError] = useState<Error | null>(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await GetAllSeoDetailsApi(url);
      setSeoData(response[0].get_seo);
    } catch (error: any) {
      if (error.status === 429) {
        navigate('/many-requests', {
          state: { retryAfter: error.data.retry_after },
        });
      } else if (error.status === 500) {
        navigate('/server-error');
      } else {
        setError(error as Error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [url]);

  return { seoData, seoDataLoading, seoDataError };
}
