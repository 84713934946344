import { useState } from 'react';
import DropDownItem from './DropDownItem';

export default function DropDownSection({ FAQ }) {
  const faqs = FAQ?.length > 0 ? FAQ[0].faqs : [];
  const [openId, setOpenId] = useState<number>(1);
  const handleToggle = (id: number) => {
    setOpenId((prevId) => (prevId === id ? -1 : id));
  };
  return (
    <section className="padding-container px-44">
      <h1 className="text-center font-sans font-normal text-[42px] leading-[76px]">
        Q&A
      </h1>
      <div>
        {faqs?.map((item) => (
          <DropDownItem
            key={item.faq_id}
            id={item.faq_id}
            question={item.question}
            answer={item.answer}
            isOpen={openId === item.faq_id}
            onToggle={() => handleToggle(item.faq_id)}
          />
        ))}
      </div>
    </section>
  );
}
