import React, { useEffect, useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import left_arrow from '../../../assets/icons/Group 1672.png';
import right_arrow from '../../../assets/icons/Group 1673.png';
import { MajorCitiesDataProps, SliderArrowProps } from './type';
import { AWS_BASE_URL_CITY } from '@/config/seviceApiConfig';
import { useNavigate } from 'react-router-dom';
import { formatNameForURL } from '@/utils/formatNameForURL';
import { motion } from 'framer-motion';

const MajorCitiesData: React.FC<MajorCitiesDataProps> = ({ data }) => {
  const sliderRef = useRef<Slider>(null);
  const navigate = useNavigate();
  const slidesToShow = 6;

  const getSettings = (dataLength: number): Settings => {
    const isCenterMode = dataLength < slidesToShow;
    const centerPaddingValue = isCenterMode
      ? `${(100 - (100 * dataLength) / slidesToShow) / 2}%`
      : '0px';

    return {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: isCenterMode ? dataLength : slidesToShow,
      slidesToScroll: 1,
      autoplay: true,
      pauseOnHover: true,
      rtl: false,
      centerMode: isCenterMode,
      centerPadding: centerPaddingValue,
      prevArrow: <CustomPrevArrow />,
      nextArrow: <CustomNextArrow />,
      responsive: [
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: isCenterMode ? dataLength : 6,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
            centerMode: isCenterMode,
            centerPadding: centerPaddingValue,
            prevArrow: <CustomPrevArrow />,
            nextArrow: <CustomNextArrow />,
          },
        },
        {
          breakpoint: 1536,
          settings: {
            slidesToShow: isCenterMode ? dataLength : 5,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
            centerMode: isCenterMode,
            centerPadding: centerPaddingValue,
            prevArrow: <CustomPrevArrow />,
            nextArrow: <CustomNextArrow />,
          },
        },
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: isCenterMode ? dataLength : 4,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
            centerMode: isCenterMode,
            centerPadding: centerPaddingValue,
            prevArrow: <CustomPrevArrow />,
            nextArrow: <CustomNextArrow />,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: isCenterMode ? dataLength : 3,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
            centerMode: isCenterMode,
            centerPadding: centerPaddingValue,
            prevArrow: <CustomPrevArrow />,
            nextArrow: <CustomNextArrow />,
          },
        },
      ],
    };
  };

  const settings = getSettings(data.length);

  function CustomPrevArrow(props: SliderArrowProps) {
    const { currentSlide, slideCount, ...restProps } = props;
    return (
      <div {...restProps} className="slick-arrow-custom slick-prev-custom">
        <img src={left_arrow} alt="Left Arrow" />
      </div>
    );
  }

  function CustomNextArrow(props: SliderArrowProps) {
    const { currentSlide, slideCount, ...restProps } = props;
    return (
      <div {...restProps} className="slick-arrow-custom slick-next-custom">
        <img src={right_arrow} alt="Right Arrow" />
      </div>
    );
  }

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  }, [data]);

  const handleNavigateToFilter = (cityName: string, suburbID: number) => {
    navigate(`/city/all-jobs/${cityName}`, { state: { suburbID } });
  };

  const imageVariants = {
    hover: {
      scale: 1.05,
      transition: {
        duration: 0.3,
        ease: 'easeInOut',
      },
    },
  };

  return (
    <Slider {...settings} className="h-[658px]" ref={sliderRef}>
      {data?.map((city) => (
        <motion.div
          key={city.suberbs_id}
          className="relative cursor-pointer"
          onClick={() =>
            handleNavigateToFilter(formatNameForURL(city.name), city.suberbs_id)
          }
          whileHover="hover"
        >
          <motion.img
            src={`${AWS_BASE_URL_CITY}${city.image}`}
            alt={city.name}
            width="237"
            height="658"
            className="bg-gradient-city-card "
            variants={imageVariants}
            loading="lazy"
          />
          <div className="absolute bottom-14 ml-8">
            <h4 className="city-card-main">{city.name}</h4>
            <h4 className="city-card-sub">{city.jobdetails_count} Jobs</h4>
          </div>
        </motion.div>
      ))}
    </Slider>
  );
};

export default MajorCitiesData;
