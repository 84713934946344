import React, { useState } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { AWS_BASE_URL_SPECIALTY } from '@/config/seviceApiConfig';
import { useNavigate } from 'react-router-dom';
import { formatNameForURL } from '@/utils/formatNameForURL';

interface SpecialtyItem {
  id: number;
  title: string;
  url: string;
  jobs: string;
}

interface SpecialtyDataProps {
  data: SpecialtyItem[];
}

const MobileSpecialtyData: React.FC<SpecialtyDataProps> = ({ data }) => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    rows: 2,
    appendDots: (dots: JSX.Element[]) => (
      <ul className="slick-dots">
        {dots.slice(0, 3).map((dot, index) => (
          <li key={index} className="inline-block m-2 cursor-pointer">
            {dot}
          </li>
        ))}
      </ul>
    ),
    customPaging: (i: number) => (
      <div
        className={`w-[0.688rem] h-[0.688rem] lg:w-[0.813rem] lg:h-[0.813rem] rounded-full mt-5 ${i === activeIndex ? 'bg-blue-400' : 'bg-gray-450'}`}
      />
    ),
    beforeChange: () => {
      const maxIndex = 2;
      setActiveIndex((oldIndex) => {
        const newIndex = oldIndex + 1;
        return newIndex > maxIndex ? 0 : newIndex;
      });
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
          rows: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.15,
          slidesToScroll: 1,
          rows: 2,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 2,
        },
      },
    ],
  };

  const filteredData = data?.filter((item) => item.job_count > 0);

  if (!filteredData || filteredData.length === 0) {
    return (
      <div className="flex items-center justify-center font-sans font-semibold text-[24px]">
        No Jobs are available
      </div>
    );
  }

  const handleNavigateToFilter = (specialty: string) => {
    navigate(`/specialty/permanent/${specialty}-jobs-in-australia`);
  };

  const imageVariants = {
    hover: {
      scale: 1.03,
      transition: {
        duration: 1,
        ease: 'easeInOut',
      },
    },
  };

  return (
    <Slider {...settings}>
      {filteredData.map((item) => (
        <div key={item.specialities_id} className="w-[260px] h-[114px]">
          <div
            className="flex items-center justify-normal gap-5"
            onClick={() =>
              handleNavigateToFilter(
                formatNameForURL(item.short_name.replace(/jobs/i, '').trim())
              )
            }
          >
            <div className="w-[158px] h-[114px]">
              <img
                src={`${AWS_BASE_URL_SPECIALTY}${item.image}`}
                alt={item.title}
                className="w-full h-full object-cover object-center"
              />
            </div>
            <div>
              <h3 className="specialty-text-main w-[88px]">
                {item.speciality_name}
              </h3>
              <p className="specialty-text-sub">{item.job_count} Jobs</p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default MobileSpecialtyData;
