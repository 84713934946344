import { getBaseUrl } from '@/config/BaseUrl';

export default async function ApplyQuickApi(
  first_name,
  last_name,
  mobile,
  email,
  profession,
  cv,
  job_id,
  state,
  seniority,
  speciality,
  hear_about_us,
  message
) {
  try {
    const requestBody = {
      first_name: first_name,
      last_name: last_name,
      mobile: mobile,
      email: email,
      profession: profession,
      cv: cv,
      job_id: job_id,
      state: state,
      seniority: seniority,
      speciality: speciality,
      hear_about_us: hear_about_us,
      message: message,
    };
    const baseUrl = getBaseUrl();
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
      body: JSON.stringify(requestBody),
    };

    const response = await fetch(
      `${baseUrl}web/quick_apply/save`,
      requestOptions
    );

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message);
    }

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    console.error('Error creating candidate profile:', error);
    throw error;
  }
}
