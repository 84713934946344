import { Link, useNavigate } from 'react-router-dom';
import HomeImage from '@/assets/images/Vector.png';
import { CommonLogo, HeroMegaMenu } from '@/components';
import useTopMegaMenu from '@/hooks/useScollTopNavMenu';
import { useState } from 'react';
import {
  TOP_NAV_LINKS,
  TOP_NAV_LINKS_COMMON,
} from '@/constants/NavbarConstants';
import useFetchStatesWithRegions from '@/hooks/useFetchStatewithRegions';
import HeroMegaMenuBlue from '@/components/mainMenu/HeroMegaMenuBlue';
import TopMenuEmployersBlue from '@/components/mainMenu/TopMenuBlue';
import TopMenuEmployers from '@/components/mainMenu/TopMenu';

function TopNavbar({ stateWithRegions, fetchStatesWithRegions }) {
  const [menuData, setMenuData] = useState([]);
  // const { stateWithRegions, fetchStatesWithRegions } =
  //   useFetchStatesWithRegions();
  const navigate = useNavigate();

  const {
    selectedLink,
    isMegaMenuOpen,
    isPolygonVisible,
    isEmployerMenuOpen,
    handleLinkClick,
    handleMouseEnter,
    handleMouseLeave,
    handleMenuMouseEnter,
    handleMenuMouseLeave,
    handlePolygonMouseEnter,
    handlePolygonMouseLeave,
    menuRef,
    megaMenuRef,
    polygonRef,
    handleNavigation,
    employerMenuRef,
  } = useTopMegaMenu();

  const handleClick = () => {
    handleNavigation();
    navigate('/');
    window.scrollTo(0, 0);
  };

  const handleBrowseJobsClick = async (event) => {
    event.preventDefault();
    try {
      await fetchStatesWithRegions();
      if (stateWithRegions) {
        setMenuData(stateWithRegions);
      }
      handleLinkClick('browserJobs', navigate);
    } catch (error) {
      console.error('Error fetching jobs data:', error);
    }
  };

  const handleEmployerMouseEnter = (event) => {
    event.preventDefault();
    handleMouseEnter('employers');
  };
  return (
    <div className="sticky top-0 z-10">
      <section className="nav-container flex items-center justify-between">
        <div className="flex items-center justify-between">
          <div className="w-[28px] h-[28px] mr-7 mt-2">
            <img
              src={HomeImage}
              width={28}
              height={28}
              className="h-full w-full object-cover object-center"
              loading="lazy"
              alt="home"
            />
          </div>
          <div>
            <CommonLogo height={57} width={207} handleClick={handleClick} />
          </div>
        </div>
        <div className="flex items-center pr-8">
          <ul className="flex lg:gap-[30px] xl:gap-[50px] 2xl:gap-[80px]">
            {TOP_NAV_LINKS_COMMON?.map((link) => (
              <div key={link.key} className="relative">
                <Link
                  to={link.href}
                  className={`text-blue-500 flex items-center cursor-pointer text-base font-normal leading-5  ${selectedLink === link.key ? 'active' : ''}`}
                  onMouseEnter={(event) => {
                    if (link.key === 'browserJobs') {
                      handleBrowseJobsClick(event);
                    } else if (link.key === 'employers') {
                      handleEmployerMouseEnter(event);
                    }
                  }}
                  onMouseLeave={() => {
                    handleMouseLeave();
                    handleMenuMouseLeave();
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    handleLinkClick(link.href, navigate);
                  }}
                >
                  {link.label === 'Browse Jobs' ? (
                    <span className="flex items-center">
                      <div className="w-4 h-4 mr-2">
                        <img
                          src={link.icon}
                          alt="Browse Jobs"
                          className="w-full h-full"
                        />
                      </div>
                      {link.label}
                    </span>
                  ) : (
                    link.label
                  )}
                </Link>
                {link.key === 'browserJobs' &&
                  selectedLink &&
                  isMegaMenuOpen && (
                    <div
                      onMouseEnter={handleMenuMouseEnter}
                      onMouseLeave={handleMenuMouseLeave}
                    >
                      <HeroMegaMenu ref={megaMenuRef} data={menuData} />
                    </div>
                  )}
                {link.key === 'employers' &&
                  selectedLink &&
                  isEmployerMenuOpen && (
                    <div
                      onMouseEnter={handleMenuMouseEnter}
                      onMouseLeave={handleMenuMouseLeave}
                    >
                      <TopMenuEmployers
                        ref={employerMenuRef}
                        data={link.subLinks}
                      />
                    </div>
                  )}
                {selectedLink === link.key && isPolygonVisible && (
                  <div
                    className="polygon-shape-top-blue"
                    onMouseEnter={handlePolygonMouseEnter}
                    onMouseLeave={handlePolygonMouseLeave}
                    ref={polygonRef}
                  ></div>
                )}
              </div>
            ))}
          </ul>
        </div>
      </section>
    </div>
  );
}

export default TopNavbar;
